import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Typography, LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useLocale, useTranslate } from 'react-admin';
import without from 'lodash/without';
import { useTitleContext } from '../../../../base/context/title';
import { getViewType } from '../../../../base/hooks/useNavigation';
import { EnumViewType } from '../../../../base/hooks/useViewType';
import { UUID_REGEX } from '../../../../constant';

const useStyles = makeStyles((theme) => ({
  pageHeaderRoot: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  titleRoot: {
    flex: 1,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '1.167',
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up(960)]: {
      fontSize: '22px',
    },
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
  },
  detailName: {
    fontWeight: 200,
    fontSize: 18,
    marginLeft: 5,
    [theme.breakpoints.up(960)]: {
      fontSize: 20,
    },
  },
  detailText: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  idWrapper: {
    display: 'flex !important',
    alignItems: 'center',
  },
  idEllipsisDot: {
    color: theme.palette.primary.light,
    backgroundColor: '#005EB821',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 2,
    '&:hover': {
      backgroundColor: '#B8770021',
    },
  },
}));

const IdShorten = (props) => {
  const { value } = props;
  const [displayFull, setDisplayFull] = useState(false);
  const classes = useStyles();

  if (typeof value !== 'string') {
    return value;
  }

  const length = displayFull ? value.length : 4;

  const handleEllipsisClick = () => {
    setDisplayFull(true);
  };

  return (
    <span className={clsx(classes.idWrapper, classes.detailText)}>
      #<span>{value.slice(0, length)}</span>
      {!displayFull && (
        <span className={classes.idEllipsisDot} onClick={handleEllipsisClick}>
          {'...'}
        </span>
      )}
    </span>
  );
};

const PageHeader = ({ breadcrumbComponent, children, pageTitleInfo, ...rest }) => {
  const { title: overrideTitle } = useTitleContext();
  const classes = useStyles();
  const { pathname } = useLocation();
  const t = useTranslate();
  const [loading, setLoading] = useState(false);
  const detailName = useSelector(({ app }) => app?.detailName);
  const [detailNameState, setDetailNameState] = useState('');
  const paths = without(pathname?.split('/') || [], '');
  const prevResourceRef = useRef('');
  const prevDetailNameRef = useRef('');
  const timeoutStopLoading = useRef();
  const locale = useLocale();
  const { label, showSubLabel = true } = pageTitleInfo;

  // Detail name able display when its value is changed (compare with prev value)
  const canDisplayDetailName = useMemo(() => {
    if (detailName !== prevDetailNameRef.current) {
      return true;
    }

    return false;
  }, [paths[0], detailName]);

  // Create heading title
  const getHeaderTitle = () => {
    const viewType = getViewType(pathname);
    let subLabel = '';

    switch (viewType) {
      case EnumViewType.SHOW:
        const id = paths.find((path) => path.match(UUID_REGEX));
        subLabel = detailNameState || <IdShorten value={id} />;
      default:
        break;
    }

    return (
      <>
        <span>{label}</span>
        {subLabel && showSubLabel && <span className={clsx(classes.detailName, classes.detailText)}>{subLabel}</span>}
      </>
    );
  };

  const headerTitle = getHeaderTitle();

  const specialRoutes = ['profile'];
  const defaultHeaderTitle = useMemo(() => {
    return paths?.length && specialRoutes.includes(paths[0]) ? t(`ra.page.${paths[0]}`) : '';
  }, [pathname, specialRoutes]);

  const Loading = () => <LinearProgress style={{ width: 100 }} />;
  const HeadingTitle = () => {
    return (
      <Typography component="div" variant="h1" className={clsx(classes.titleRoot, 'title')}>
        {overrideTitle || defaultHeaderTitle || headerTitle}
      </Typography>
    );
  };

  useEffect(() => {
    prevResourceRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    setDetailNameState('');
  }, [pathname]);

  useEffect(() => {
    prevResourceRef.current = paths[0];
  }, [paths[0]]);

  // Handle loading and display detail name (resource name + #id)
  useEffect(() => {
    if (!showSubLabel) {
      setLoading(false);
      return;
    }
    setLoading(true);
    clearTimeout(timeoutStopLoading.current);
    timeoutStopLoading.current = setTimeout(() => setLoading(false), 1000);
    let tmpDetailname = detailName;
    if (detailName && canDisplayDetailName) {
      if (typeof tmpDetailname !== 'string' && Object.keys(tmpDetailname || {}).includes(locale)) {
        tmpDetailname = tmpDetailname[locale] || null;
      }

      if (tmpDetailname) {
        setDetailNameState(tmpDetailname);
        clearTimeout(timeoutStopLoading.current);
        setLoading(false);
        prevDetailNameRef.current = tmpDetailname;
      }
    }

    return function cleanUp() {
      clearTimeout(timeoutStopLoading.current);
    };
  }, [pathname, detailName, locale, showSubLabel]);

  return (
    <Box
      className={clsx(classes.pageHeaderRoot, 'page-header')}
      mt={{
        xs: 4,
        md: 5,
        lg: 6,
      }}
      mb={{
        xs: 4,
        md: 5,
        lg: 6,
      }}
      {...rest}
    >
      {loading ? <Loading /> : <HeadingTitle />}
      <Box flex={1} pl={1} display="flex" flexDirection="row" justifyContent="flex-end">
        {breadcrumbComponent}
      </Box>

      {children}
    </Box>
  );
};

export default PageHeader;
