/* eslint-disable react/require-default-props */
import { Box, TextField, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import WheelPicker from './wheel-picker';

const useStyles = makeStyles({
  input: {
    "& [class*='focused'] [class*='notchedOutline']": {
      borderWidth: 1,
    },
  },
  button: {
    margin: 0,
    '&, &:hover': {
      boxShadow: 'none',
    },
  },
});

const AddableWheelPicker = ({
  wheelPickerProps, data, onAdd, disabled, addLabel, defaultValue, scrollerId,
}) => {
  const [value, setValue] = useState();
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 8,
      }}
    >
      <WheelPicker
        disabled={disabled}
        data={data}
        defaultValue={defaultValue}
        scrollerId={scrollerId}
        {...wheelPickerProps}
      />
      {!disabled && (
        <>
          <TextField
            className={classes.input}
            label={false}
            value={value}
            onChange={({ target: { value: inputValue } }) => {
              if (/^\d+(\.\d{0,2})?$/.test(inputValue) || inputValue === '') {
                setValue(inputValue);
              }
            }}
            placeholder={translate('ra.action.typeYourNumber')}
          />
          <Button
            className={classes.button}
            onClick={() => {
              const newValue = parseFloat(value);
              setValue('');
              if (data.includes(newValue)) return;
              onAdd(newValue);
            }}
            label={addLabel}
            disabled={!parseFloat(value)}
            variant="contained"
          />
        </>
      )}
    </Box>
  );
};

AddableWheelPicker.propTypes = {
  wheelPickerProps: PropTypes.object,
  data: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
  disabled: PropTypes.bool,
  addLabel: PropTypes.node,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollerId: PropTypes.string.isRequired,
};

export default AddableWheelPicker;
