import { Create, useDataProvider, useTranslate } from 'react-admin';
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import CampaignCreateUpdateForm from './components/CampaignCreateUpdateForm';
import resourceSlug from '../../constant/resource-slug';
import { useCreateCustom } from '../../base/hooks';
import { formatCampaignData, parseClonedCampaignData } from './components/utils';

const CampaignCreate = props => {
  const { search } = useLocation();
  const dp = useDataProvider();
  const [clonedRecord, setClonedRecord] = useState();
  const t = useTranslate();

  const {
    create, createLoading: isCreating,
  } = useCreateCustom({
    resource: resourceSlug.CAMPAIGN,
    redirectTo: 'list',
    successMessage: 'resources.campaign.created-successfully',
    resourceTranslated: t(`resources.${resourceSlug.CAMPAIGN}.name`),
  });

  const handleSave = values => {
    create({
      resource: resourceSlug.CAMPAIGN,
      payload: {
        data: formatCampaignData(values),
      },
    });
  };

  const handleGetClonedCampaign = async campaignId => {
    const { data } = await dp.getOne(resourceSlug.CAMPAIGN, {
      id: campaignId,
    });

    setClonedRecord(parseClonedCampaignData(data));
  };

  useEffect(() => {
    if (!search) return;

    const queryString = search.replace('?', '');
    const queryObject = parse(queryString);
    if (!queryObject.cloneId) return;
    handleGetClonedCampaign(queryObject.cloneId);
  }, [search]);

  return (
    <Box
      component={Create}
      {...props}
      width={{
        md: 960,
      }}
      mx="auto"
    >
      <CampaignCreateUpdateForm
        save={handleSave}
        saving={isCreating}
        clonedRecord={clonedRecord}
      />
    </Box>
  );
};

export default CampaignCreate;
