import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import InfinitePagination from '../../../../base/components/ra/list/InfinitePagination';
import resourceSlug from '../../../../constant/resource-slug';
import { sanitizeObject } from '../../../../services/util';
import { cacheCampaignActionLogByPage, updateCurrentCampaignActionLogPage } from '../../../../services/redux/campaign/campaign.action';

const ChangeLogPagination = () => {
  const {
    actionLogList, currentActionLogPage,
  } = useSelector(({ campaign }) => campaign);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const {
    filterValues, currentSort, perPage,
  } = useListContext();
  const { id } = useParams();

  const disableNext = useMemo(() => !actionLogList?.[currentActionLogPage]?.nextPagination, [currentActionLogPage, actionLogList]);

  const handlePageChange = useCallback(
    async newPage => {
      try {
        if (!actionLogList[newPage]) {
          const nextPagination = actionLogList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(`${resourceSlug.ACTION_LOG}/${id}/${resourceSlug.CAMPAIGN}`, {
            pagination: {
              perPage,
              cachedPage: newPage,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
              q: sanitizeObject({
                nextPagination: JSON.stringify(nextPagination),
              }),
            },
          });

          dispatch(
            cacheCampaignActionLogByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentCampaignActionLogPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [actionLogList, currentActionLogPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentActionLogPage === 1}
      onNextPage={() => {
        handlePageChange(currentActionLogPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentActionLogPage - 1);
      }}
      currentPage={currentActionLogPage}
    />
  );
};

export default ChangeLogPagination;
