import CampaignCreate from './campaign.create';
import CampaignList from './campaign.list';
import CampaignUpdate from './campaign.edit';
import CampaignShow from './campaign.show';

export default {
  create: CampaignCreate,
  edit: CampaignUpdate,
  list: CampaignList,
  show: CampaignShow,
};
