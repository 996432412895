export const CAMPAIGN_TYPES = {
  DAILY_BONUS: 'DAILY_BONUS',
  // Temporarily unavailable
  // MARKETING_FREE_SPIN: 'MARKETING_FREE_SPIN',
};

export const CAMPAIGN_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
};

export const CAMPAIGN_ISSUERS = {
  OPERATOR: 'OPERATOR',
  NG: 'NG',
};

export const CAMPAIGN_UNIT_EXPIRE_IN = {
  HOURS: 'HOURS',
  DAYS: 'DAYS',
};

export const CAMPAIGN_PLAYER_STATUS = {
  EXPIRED: 'EXPIRED',
  USED: 'USED',
  ISSUED: 'ISSUED',
};

export const ACTION_TYPE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  ADD_PLAYER: 'ADD PLAYER',
  REMOVE_PLAYER: 'REMOVE PLAYER',
};

export const ACTION_MODULE = {
  MAKERTING_CAMPAIGN: 'Marketing Campaigns',
  PLAYER_FREE_SPINS: 'Player Free Spins',
};
