import React, { useRef } from 'react';
import moment from 'moment';
import { useField } from 'react-final-form';
import { range } from 'lodash';
import DateRangePicker from '../../../../../base/components/ra/inputs/DateRangePicker';
import { CAMPAIGN_TYPES } from '../../../../../constant/campaign';

const ExpirationTimeInput = ({
  isEditForm, source, label, disabled,
}) => {
  const { input: { value } } = useField(source);
  const { input: { value: campaignType } } = useField('campaignType');
  const calenderValues = useRef([]);

  const isStartTimeDisabled = isEditForm && value?.[0] && !moment(value?.[0]).isAfter(moment());
  const isDailyBonus = campaignType === CAMPAIGN_TYPES.DAILY_BONUS;

  const getDisabledDate = current => {
    if (current == null) {
      return null;
    }

    const startDate = calenderValues.current[0] && moment(calenderValues.current[0].format());
    const endDate = calenderValues.current[1] && moment(calenderValues.current[1].format());

    const minDate = moment().endOf('day');

    const currentDate = moment(current.format()).startOf('hour');

    const isOver1YearDuration1 = startDate ? currentDate.diff(startDate, 'years', true) > 1 : false;
    const isOver1YearDuration2 = endDate ? endDate.diff(currentDate, 'years', true) > 1 : false;

    const isNotFromTomorrow = current.isBefore(minDate);

    return isOver1YearDuration1 || isOver1YearDuration2 || isNotFromTomorrow;
  };

  return (
    <DateRangePicker
      label={label}
      disabledDate={getDisabledDate}
      disabledTime={() => ({
        disabledHours: () => (isDailyBonus ? range(0, 24) : []),
        disabledMinutes: () => range(0, 60),
      })}
      disabled={disabled || [isStartTimeDisabled, false]}
      isRequired
      timeFormat="HH:mm"
      showTime={{
        showHour: true,
        showMinute: true,
        defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
      }}
      source={source}
      onCalendarChange={values => { calenderValues.current = values; }}
    />
  );
};

export default ExpirationTimeInput;
