/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ReferenceField, SelectField, TextField, useLocale, useTranslate } from 'react-admin';
import { Card } from '@material-ui/core';
import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';
import { NavigationContext } from '../../../../App';
import CustomizedList from '../../../../base/components/customized/CustomizedList';
import CampaignDetailsTabs, { CAMPAIGN_TABS, CampaignTab } from '../CampaignDetailsTabs';
import { UUID_REGEX } from '../../../../constant';
import resourceSlug from '../../../../constant/resource-slug';
import { DateField } from '../../../../base/components/ra/fields';
import { CAMPAIGN_PLAYER_STATUS } from '../../../../constant/campaign';
import CampaignPlayerListAction from './CampaignPlayerActions';
import CampaignPlayerFilterWrapper from './CampaignPlayerFilters';
import { useTableListStyles } from '../utils';
import CommonWrapper from '../../../../base/components/reverse-integration/common-wrapper';
import CustomLinkField from '../../../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../../../services/util/stringUtil';
import CampaignPlayerPagination from './CampaignPlayerPagination';

const PlayerFreeSpinList = props => {
  const t = useTranslate();
  const locale = useLocale();
  const classes = useTableListStyles();

  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();

  const {
    playerList, currentPlayerPage,
  } = useSelector(({ campaign }) => campaign);

  const players = useMemo(() => playerList[currentPlayerPage]?.data || [], [playerList, currentPlayerPage]);

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.campaign.details-title'),
        showSubLabel: false,
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.campaign.name'),
          link: `/${resourceSlug.CAMPAIGN}`,
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);

  return (
    <Card>
      <CampaignDetailsTabs currentTab={CAMPAIGN_TABS.PLAYER_FREE_SPINS}>
        <CampaignTab
          value={CAMPAIGN_TABS.PLAYER_FREE_SPINS}
          index={CAMPAIGN_TABS.PLAYER_FREE_SPINS}
        >
          <CommonWrapper className={classes.wrapper}>
            <CustomizedList
              {...props}
              actions={<CampaignPlayerListAction />}
              filters={<CampaignPlayerFilterWrapper />}
              syncWithLocation
              hasList
              hasCreate={false}
              hasEdit={false}
              hasShow={false}
              exporter={false}
              className={classes.root}
              pagination={<CampaignPlayerPagination />}
              controllerProps={{
                data: keyBy(players, 'id'),
                ids: players?.map(({ id }) => id),
              }}
              sort={{
                field: 'issuedDate',
                order: 'DESC',
              }}
            >
              <CustomLinkField
                source="playerId"
                label="resources.campaign.fields.player-id"
                href={record => `/#/${resourceSlug.PLAYER}/${record.playerId || record.id}/show`}
                title={record => transformIdValue(record.playerId || record.id)}
              />
              <ReferenceField
                source="groupId"
                label="resources.group.name"
                reference={resourceSlug.GROUP}
                link={(record, reference) => `/${reference}/${record.groupId}/show`}
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source="brandId"
                label="resources.brand.name"
                reference={resourceSlug.BRAND}
                link={(record, reference) => `/${reference}/${record.brandId}/show`}
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField
                source="currencyCode"
                label="resources.currency.name"
                sortable={false}
                emptyText="-"
              />
              <ReferenceField
                reference="game"
                source="gameId"
                label="resources.campaign.fields.game"
                link={(record, reference) => `/${reference}/${record.gameId}/show`}
                sortable={false}
                emptyText="-"
              >
                <TextField
                  source={`name.${locale}`}
                />
              </ReferenceField>
              <DateField
                source="issuedDate"
                label="resources.campaign.fields.issued-date"
                sortable={false}
              />
              <DateField
                source="usedDate"
                label="resources.campaign.fields.claimed-date"
                sortable={false}
                emptyText="-"
              />
              <TextField
                source="issuedQuantity"
                label="resources.campaign.fields.issued-quantity"
                sortable={false}
              />
              <TextField
                source="claimedQuantity"
                label="resources.campaign.fields.claimed-quantity"
                sortable={false}
              />
              <SelectField
                source="status"
                label="resources.campaign.fields.status"
                choices={Object.values(CAMPAIGN_PLAYER_STATUS).map(status => ({
                  id: status,
                  name: t(`resources.campaign.player-status.${status}`),
                }))}
                sortable={false}
              />
            </CustomizedList>
          </CommonWrapper>

        </CampaignTab>
      </CampaignDetailsTabs>
    </Card>

  );
};

export default PlayerFreeSpinList;
