import React from 'react';
import { FunctionField } from 'react-admin';
import { Box } from '@material-ui/core';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';

export default function GameShow(props) {
  return (
    <WealthShowGuesser
      {...props}
      excludeFields={['baseBet']}
      extraInformBlocks={[
        <FunctionField
          source="baseBet"
          render={record => {
            if (Array.isArray(record.gameConfig?.multiBaseBets)) {
              return (
                <Box
                  display="flex"
                  sx={{
                    gap: 8,
                  }}
                >
                  {record.gameConfig?.multiBaseBets
                    .sort((a, b) => a - b)
                    .map(item => (
                      <Box
                        component="span"
                        key={item}
                      >
                        {item}
                      </Box>
                    ))}
                </Box>
              );
            }
            return record.baseBet;
          }}
        />,
      ]}
    />
  );
}
