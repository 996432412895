import { Space } from 'antd';
import React, { useMemo } from 'react';
import { FormDataConsumer, useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import { useField, useFormState } from 'react-final-form';
import { IntegerInput, SelectInput, TextInput } from '../../../../../base/components/ra/inputs';
import { CAMPAIGN_ISSUERS, CAMPAIGN_TYPES, CAMPAIGN_UNIT_EXPIRE_IN } from '../../../../../constant/campaign';
import { insertIf } from '../../../../../services/util';
import { isSystemAdmin } from '../../../../../services/util/auth';
import GroupInput from './GroupInput';
import BrandInput from './BrandInput';
import Switcher from '../../../../../base/components/common/Switcher';
import InputWithTooltipWrapper from '../../../../../base/components/common/InputWithTooltipWrapper';
import ExpirationTimeInput from './ExpirationTimeInput';

const BasicInfoStep = ({ isEditForm }) => {
  const t = useTranslate();
  const isSA = isSystemAdmin();
  const campaignType = useField('campaignType')?.input?.value;
  const { initialValues } = useFormState();
  const cantEdit = isEditForm && initialValues?.isActive;

  const campaignTypeChoices = useMemo(
    () => Object.values(CAMPAIGN_TYPES).map(type => ({
      id: type,
      name: t(`resources.campaign.type.${type}`),
    })),
    [t],
  );

  const issuerChoices = useMemo(
    () => [
      ...insertIf(isSA, {
        id: CAMPAIGN_ISSUERS.NG,
        name: t(`resources.campaign.issuer.${CAMPAIGN_ISSUERS.NG}`),
      }),
      ...insertIf(campaignType !== CAMPAIGN_TYPES.DAILY_BONUS, {
        id: CAMPAIGN_ISSUERS.OPERATOR,
        name: t(`resources.campaign.issuer.${CAMPAIGN_ISSUERS.OPERATOR}`),
      }),
    ],
    [t, campaignType, isSA],
  );

  const issuedExpirationChoices = useMemo(
    () => Object.values(CAMPAIGN_UNIT_EXPIRE_IN).map(unit => ({
      id: unit,
      name: t(`resources.campaign.unit.${unit}`),
    })),
    [t],
  );

  return (
    <>
      <Box width="fit-content">
        <Switcher
          source="isActive"
          checkedLabel={t('resources.campaign.active')}
          uncheckedLabel={t('resources.campaign.inactive')}
        />
      </Box>

      <Box
        component={Space}
        width="100%"
        size="middle"
      >
        <Box
          width={{
            md: 345,
          }}
        >
          <TextInput
            label="resources.campaign.fields.name"
            source="campaignName"
            required
          />
        </Box>

        <Box
          width={{
            md: 345,
          }}
        >
          <InputWithTooltipWrapper
            source="campaignCode"
            title={t('resources.campaign.fields.code')}
            tooltip={t('resources.campaign.fields.code-tooltip')}
            required
            disabled={isEditForm}
          >
            <TextInput />
          </InputWithTooltipWrapper>
        </Box>
      </Box>
      <Box
        component={Space}
        width="100%"
        size="middle"
      >
        <Box
          width={{
            md: 345,
          }}
        >
          <SelectInput
            source="campaignType"
            label="resources.campaign.fields.type"
            choices={campaignTypeChoices}
            defaultValue={campaignTypeChoices.length === 1 && campaignTypeChoices[0].id}
            isRequired
            fullWidth
            disabled={isEditForm}
          />
        </Box>

        <Box
          width={{
            md: 345,
          }}
        >
          <SelectInput
            source="issuer"
            label="resources.campaign.fields.issuer"
            choices={issuerChoices}
            isRequired
            fullWidth
            defaultValue={issuerChoices.length === 1 ? issuerChoices[0].id : undefined}
            disabled={isEditForm}
          />
        </Box>
      </Box>
      <Box
        component={Space}
        width="100%"
        size="middle"
      >
        <Box
          width={{
            md: 345,
          }}
        >
          <GroupInput
            source="groupId"
            disabled={isEditForm}
            isRequired
          />
        </Box>
        <Box
          width={{
            md: 345,
          }}
        >
          <FormDataConsumer>
            {({
              formData, ...rest
            }) => (
              <BrandInput
                groupId={formData.groupId}
                source="brandId"
                disabled={isEditForm}
                isRequired
                {...rest}
              />
            )}
          </FormDataConsumer>
        </Box>
      </Box>
      <Box
        component={Space}
        width="100%"
        size="middle"
      >
        <Box
          width={{
            md: 345,
          }}
        >
          <InputWithTooltipWrapper
            source="expDate"
            title={t('resources.campaign.fields.exp-date')}
            tooltip={t('resources.campaign.fields.exp-date-tooltip')}
            required
            isEditForm={isEditForm}
            disabled={cantEdit}
          >
            <ExpirationTimeInput />
          </InputWithTooltipWrapper>

        </Box>
        <Box
          width={{
            md: 345,
          }}
          display="flex"
          sx={{
            gap: 8,
          }}
        >
          <InputWithTooltipWrapper
            source="freeSpinExpireIn"
            title={t('resources.campaign.fields.issued-exp-date')}
            tooltip={t('resources.campaign.fields.issued-exp-date-tooltip')}
            required
            disabled={cantEdit}
          >
            <IntegerInput />
          </InputWithTooltipWrapper>
          <Box width={96}>
            <SelectInput
              choices={issuedExpirationChoices}
              source="freeSpinExpireUnit"
              label={false}
              isRequired
              fullWidth
              style={{
                minWidth: '100%',
              }}
              defaultValue={CAMPAIGN_UNIT_EXPIRE_IN.DAYS}
              disabled={cantEdit}
            />
          </Box>
        </Box>
      </Box>
      <TextInput
        label="resources.campaign.fields.desc"
        source="description"
        fullWidth
        multiline
        resettable
      />
    </>
  );
};

export default BasicInfoStep;
