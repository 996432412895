import React, { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import resourceSlug from '../../../../../constant/resource-slug';
import { useAuthUser } from '../../../../../base/hooks';
import { AutocompleteArrayInput } from '../../../../../base/components/ra/inputs';

const CurrenciesInput = ({
  brandId, ...otherProps
}) => {
  const user = useAuthUser();
  const t = useTranslate();
  const [currencies, setCurrencies] = useState([]);

  const brand = brandId || user?.brands?.[0]?.id;
  const dp = useDataProvider();

  useEffect(() => {
    if (!brand) return;
    dp.getList(resourceSlug.BRAND, {
      pagination: {
        perPage: 1,
        page: 1,
      },
      filter: {
        'id||$eq||': brand,
      },
    }).then(({ data }) => {
      setCurrencies(data?.[0]?.currencies);
    });
  }, [brand]);

  return (
    <AutocompleteArrayInput
      label={t('resources.brand.fields.currencies')}
      resettable
      choices={currencies}
      translateChoice={false}
      format={data => data?.map(item => item.id)}
      parse={ids => ids?.map(id => currencies.find(currency => currency.id === id))}
      isRequired
      {...otherProps}
    />
  );
};

CurrenciesInput.propTypes = {
  source: PropTypes.string,
};

CurrenciesInput.defaultProps = {
  source: 'currencies',
};

export default CurrenciesInput;
