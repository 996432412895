/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslate } from 'react-admin';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import { sanitizeObject } from '../../services/util';
import MarketingCampaignCheckbox from './components/MarketingCampaignCheckbox';
import { BooleanInput } from '../../base/components/ra/inputs';

function GameEdit(props) {
  const translate = useTranslate();
  return (
    <WealthEditGuesser
      {...props}
      excludeFields={['enabled', 'isAvailable', 'isMcEnabled', 'isHotGame', 'isNewGame']}
      transform={sanitizeObject}
      fieldOrder={['name', 'gameId', 'gameType', 'playUrl', 'gameLogicUrl', 'desc', 'preview', 'isNewGame', 'isHotGame', 'isMcEnabled']}
    >
      <BooleanInput
        label={translate('resources.game.fields.isNewGame')}
        source="isNewGame"
      />
      <BooleanInput
        label={translate('resources.game.fields.isHotGame')}
        source="isHotGame"
      />
      <MarketingCampaignCheckbox source="isMcEnabled" />
    </WealthEditGuesser>
  );
}

export default GameEdit;
