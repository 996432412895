/* eslint-disable */

/* Clone from the library react-wheelpicker to customize */

Object.defineProperty(exports, '__esModule', {
  value: true,
});

const _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

const _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

const _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

const _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

const _createClass2 = require('babel-runtime/helpers/createClass');

const _createClass3 = _interopRequireDefault(_createClass2);

const _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

const _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

const _inherits2 = require('babel-runtime/helpers/inherits');

const _inherits3 = _interopRequireDefault(_inherits2);

const _react = require('react');

const _react2 = _interopRequireDefault(_react);

require('./index.css');

const _applyStyle = require('./applyStyle');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule
    ? obj
    : {
        default: obj,
      };
}

const WheelPicker = (function (_React$Component) {
  (0, _inherits3.default)(WheelPicker, _React$Component);

  function WheelPicker() {
    (0, _classCallCheck3.default)(this, WheelPicker);

    const _this = (0, _possibleConstructorReturn3.default)(
      this,
      (WheelPicker.__proto__ || (0, _getPrototypeOf2.default)(WheelPicker)).call(this),
    );

    _this._scrollTimer = null;
    _this.handleScroll = _this.handleScroll.bind(_this);
    return _this;
  }

  (0, _createClass3.default)(WheelPicker, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        let height = this.props.height || 40;
        height = this.props.animation === 'wheel' ? 40 : height;
        const scroller = document.getElementById(this.props.scrollerId);
        scroller.addEventListener('scroll', this.handleScroll);

        scroller.scroll({
          top: 1,
          behavior: 'smooth',
        });
        scroller.scroll({
          top: 0,
          behavior: 'smooth',
        });

        // scroll to index of default selection
        let y = this.props.defaultSelection * height - 1;
        y = y === -1 ? 0 : y;
        scroller.scroll({
          top: y,
          behavior: 'smooth',
        });
      },
    },
    {
      key: 'handleScroll',
      value: function handleScroll(e) {
        const _this2 = this;

        let height = this.props.height || 40; // required to calculate which item should be selected on scroll
        height = this.props.animation === 'wheel' ? 40 : height;

        // if there is already a timeout in process cancel it
        if (this._scrollTimer) clearTimeout(this._scrollTimer);

        const scroll = e.srcElement.scrollTop; // scroll value

        // itemInSelectorArea height of area available to scroll / height of individual item
        const itemInSelectorArea = parseInt((scroll + height / 2) / height, 10); // add (height/2) to adjust error
        // this.props.updateSelection(itemInSelectorArea)

        if (itemInSelectorArea < this.props.data.length) {
          document
            .getElementById(`${this.props.scrollerId}-scroll-item--${itemInSelectorArea}`)
            .classList.add('selected-time');

          for (let i = 0; i < this.props.data.length; i++) {
            if (i !== itemInSelectorArea) {
              document.getElementById(`${this.props.scrollerId}-scroll-item--${i}`).classList.remove('selected-time');
            }
          }
        }

        function finishedScrolling(selectorAreaHeight, id, updateSelection) {
          updateSelection(itemInSelectorArea);
          const fix = document.getElementById(id);
          let y = itemInSelectorArea * selectorAreaHeight - 1;
          y = y === -1 ? 0 : y;
          // console.log('scroll to = ', y)
          fix.scroll({
            top: y,
            behavior: 'smooth',
          });
        }

        this._scrollTimer = setTimeout(
          () => finishedScrolling(height, _this2.props.scrollerId, _this2.props.updateSelection),
          60,
        );

        (0, _applyStyle.applyStyle)(
          this.props.scrollerId,
          itemInSelectorArea,
          this.props.data.length,
          this.props.animation,
        );
      },
    },
    {
      key: 'renderListItems',
      value: function renderListItems() {
        const _this3 = this;

        let height = this.props.height || 40;
        height = this.props.animation === 'wheel' ? 40 : height;
        return this.props.data.map(function (item, index) {
          return index === 0
            ? _react2.default.createElement(
                'div',
                {
                  className: 'scroll-item-container',
                  style: {
                    minHeight: `${height}px`,
                    maxHeight: `${height}px`,
                  },
                },
                _react2.default.createElement(
                  'div',
                  {
                    key: item,
                    id: `${_this3.props.scrollerId}-scroll-item--${index}`,
                    className: `scroll-item selected-time ${_this3.props.itemClassName}`,
                    style: {
                      minHeight: `${height}px`,
                      maxHeight: `${height}px`,
                      fontSize: `${_this3.props.fontSize}px`,
                    },
                  },
                  _react2.default.createElement(_this3.props.DefaultButton || 'div', {
                    key: `default-${item}`,
                    data: item,
                    onClick: function onClick(e) {
                      if (_this3.props.disabled) return;
                      _this3.props.onDefault(item);
                    },
                    defaultValue: _this3.props.defaultValue,
                    disabled: _this3.props.disabled,
                  }),
                  item,
                  _react2.default.createElement(_this3.props.DeleteButton || 'div', {
                    key: `delete-${item}`,
                    data: item,
                    onClick: function onClick(e) {
                      if (_this3.props.disabled) return;
                      _this3.props.onDelete(item);
                    },
                    defaultValue: _this3.props.defaultValue,
                    disabled: _this3.props.disabled,
                  }),
                ),
              )
            : _react2.default.createElement(
                'div',
                {
                  className: 'scroll-item-container',
                  style: {
                    minHeight: `${height}px`,
                    maxHeight: `${height}px`,
                  },
                },
                _react2.default.createElement(
                  'div',
                  {
                    key: item,
                    id: `${_this3.props.scrollerId}-scroll-item--${index}`,
                    className: 'scroll-item',
                    style: {
                      minHeight: `${height}px`,
                      maxHeight: `${height}px`,
                      fontSize: `${_this3.props.fontSize}px`,
                    },
                  },
                  _react2.default.createElement(_this3.props.DefaultButton || 'div', {
                    key: `default-${item}`,
                    data: item,
                    onClick: function onClick(e) {
                      if (_this3.props.disabled) return;
                      _this3.props.onDefault(item);
                    },
                    defaultValue: _this3.props.defaultValue,
                    disabled: _this3.props.disabled,
                  }),
                  item,
                  _react2.default.createElement(_this3.props.DeleteButton || 'div', {
                    key: `delete-${item}`,
                    data: item,
                    onClick: function onClick(e) {
                      if (_this3.props.disabled) return;
                      _this3.props.onDelete(item);
                    },
                    defaultValue: _this3.props.defaultValue,
                    disabled: _this3.props.disabled,
                  }),
                ),
              );
        });
      },
    },
    {
      key: 'render',
      value: function render() {
        let height = this.props.height || 40;
        height = this.props.animation === 'wheel' ? 40 : height;
        const parentHeight = this.props.parentHeight || height * this.props.data.length || this.props.data.length * 40;
        return _react2.default.createElement(
          'div',
          {
            className: 'scroll-select-container',
            style: {
              height: `${parentHeight}px`,
            },
          },
          _react2.default.createElement('div', {
            className: 'scroll-selector-area',
            style: {
              height: `${height}px`,
              top: `${parentHeight / 2 - height / 2}px`,
            },
            id: `${this.props.scrollerId}--scroll-selector-area`,
          }),
          _react2.default.createElement(
            'div',
            {
              className: 'scroll-select-list',
              id: this.props.scrollerId,
              style: {
                minHeight: `${height}px`,
                maxHeight: `${height}px`,
                paddingTop: `${parentHeight / 2 - height / 2}px`,
                paddingBottom: `${parentHeight / 2 - height / 2}px`,
              },
            },
            this.renderListItems(),
          ),
        );
      },
    },
  ]);
  return WheelPicker;
})(_react2.default.Component);

exports.default = WheelPicker;
