import React, { useEffect, useMemo, useState } from 'react';
import { SelectInput, useTranslate, useLocale } from 'react-admin';
import { useField, useForm } from 'react-final-form';
import moment from 'moment';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localeZhCn from 'antd/es/date-picker/locale/zh_CN';
import localeTh from 'antd/es/date-picker/locale/th_TH';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'querystring';
import { DatePicker } from 'antd';
import { get, range } from 'lodash';
import { Box } from '@material-ui/core';
import CustomizedFilterWrapper from '../../../base/components/customized/CustomizedFilterWrapper';
import resourceSlug from '../../../constant/resource-slug';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import BrandSelector from '../../../base/components/customized/common-input/BrandSelector';
import { useAuthUser, useEffectAfterMount } from '../../../base/hooks';
import { TextInput } from '../../../base/components/ra/inputs';
import { CAMPAIGN_ISSUERS, CAMPAIGN_STATUS } from '../../../constant/campaign';
import { insertIf } from '../../../services/util';
import { isSystemAdmin } from '../../../services/util/auth';

const { RangePicker } = DatePicker;

const BRAND_KEY = 'brandId';
const GROUP_KEY = 'groupId';

const CampaignFilterWrapper = () => {
  const t = useTranslate();
  const isSA = isSystemAdmin();

  const issuerChoices = useMemo(
    () => [
      {
        id: CAMPAIGN_ISSUERS.OPERATOR,
        name: t(`resources.campaign.issuer.${CAMPAIGN_ISSUERS.OPERATOR}`),
      },
      ...insertIf(isSA, {
        id: CAMPAIGN_ISSUERS.NG,
        name: t(`resources.campaign.issuer.${CAMPAIGN_ISSUERS.NG}`),
      }),
    ],
    [t],
  );

  return (
    <CustomizedFilterWrapper>
      <ExpirationDateInput
        source={['startTime||$gte', 'endTime||$lte']}
        label={t('resources.campaign.fields.start-end-date')}
        resource={resourceSlug.CAMPAIGN}
      />
      <GroupFilter />
      <BrandFilter />
      <TextInput
        source="name||$cont"
        label="resources.campaign.fields.name"
        resettable
      />
      <TextInput
        source="campaignCode||$eq"
        label="resources.campaign.fields.code"
        resettable
      />
      <SelectInput
        label="resources.campaign.fields.issuer"
        source="issuer"
        choices={issuerChoices}
        variant="outlined"
        options={{
          SelectProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          },
        }}
        resettable
      />
      <SelectInput
        label="resources.campaign.fields.status"
        source="status"
        choices={Object.values(CAMPAIGN_STATUS).map(item => ({
          id: item,
          name: t(`resources.campaign.status.${item}`),
        }))}
        resettable
        variant="outlined"
        options={{
          SelectProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          },
        }}
      />
    </CustomizedFilterWrapper>
  );
};

const GroupFilter = () => {
  const user = useAuthUser();
  const userGroupId = user.group?.id;

  return (
    <GroupSelector
      source={GROUP_KEY}
      defaultValue={userGroupId}
      disabled={Boolean(userGroupId)}
    />
  );
};

const BrandFilter = () => {
  const user = useAuthUser();
  const form = useForm();
  const groupId = useField(GROUP_KEY)?.input?.value;
  const brandId = useField(BRAND_KEY)?.input?.value;
  const userBrandId = user?.brands?.[0]?.id;

  useEffectAfterMount(() => {
    if (!brandId || !groupId) return;
    form.change(BRAND_KEY, undefined);
  }, [groupId]);

  return (
    <BrandSelector
      source={BRAND_KEY}
      defaultValue={userBrandId}
      disabled={Boolean(userBrandId)}
      filter={{
        'group.id': groupId,
      }}
    />
  );
};

const localLocale = moment();

const getAntDesignLocale = locale => {
  switch (locale) {
    case 'cn':
      return localeZhCn;
    case 'th':
      return localeTh;
    case 'en':
    default:
      return localeEn;
  }
};

const ExpirationDateInput = props => {
  const {
    source,
    resource,
    label,
    dateRange: initDateRange,
    disabled = false,
    ...rest
  } = props;

  const [dateRange, setDateRange] = useState(() => {
    const hasDateString = Array.isArray(initDateRange) && initDateRange.some(item => typeof item === 'string');
    let result = null;
    if (hasDateString) {
      result = initDateRange.map(item => moment(item));
    }
    return result || initDateRange || [null, null];
  });

  const history = useHistory();
  const form = useForm();

  const formValues = form.getState().values;

  const [startDateString, endDateString] = [formValues[source[0]] || '', formValues[source[1]] || ''];

  const locale = useLocale();
  const antDesignLocale = getAntDesignLocale(locale);

  let localeInMoment = locale;
  if (locale === 'cn') {
    localeInMoment = 'zh-cn';
  }

  const paramsFilter = useSelector(state => state.admin.resources[resource]?.list.params);

  const onChangeRangePicker = dates => {
    const [start, end] = dates || [null, null];
    setDateRange([start, end]);

    // `dates` is null => cleanup date range!
    if (!dates?.[0]) {
      form.change(source[0], null);
    }
    if (!dates?.[1]) {
      form.change(source[1], null);
    }
  };

  useEffect(() => {
    if (dateRange[0]) {
      form.change(source[0], moment(dateRange[0]).toISOString());
    }
    if (dateRange[1]) {
      form.change(source[1], moment(dateRange[1]).toISOString());
    }
  }, [dateRange]);

  useEffect(() => {
    const { filter } = queryString.parse(history.location.search.substring(1)) || {};
    const objFilter = filter ? JSON.parse(filter) : {};
    const startDate = objFilter?.[source[0]] || paramsFilter?.filter?.[source[0]];
    const endDate = objFilter?.[source[1]] || paramsFilter?.filter?.[source[1]];

    setDateRange([startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]);
  }, []);

  // Get date format depend on locale.
  // Example:
  //    + locale of `th`: DD/MM/YYYY
  //    + locale of `en` or `cn`: MM/DD/YYYY
  const dateFormat = useMemo(() => `${get(localLocale.locale(localeInMoment), '_locale._config.longDateFormat.L', 'MM/DD/YYYY')} HH`, [localeInMoment]);

  const dateRangeOnCalendar = useMemo(() => {
    const isChoosing = dateRange.some(d => d === null);
    if (isChoosing) {
      return dateRange;
    }
    return [startDateString ? moment(startDateString) : null, endDateString ? moment(endDateString) : null];
  }, [startDateString, endDateString, dateRange]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        border: '1px solid #0000003B',
        borderRadius: '4px',
        background: '#FFFFFF',
        marginTop: '8px',
        position: 'relative',
      }}
    >
      {/* Background of Label */}
      <Box
        component="label"
        sx={{
          position: 'absolute',
          top: -3,
          left: 0,
          padding: '0 6px',
          maxWidth: '1000px',
          background: '#FFFFFF',
          color: '#FFFFFF',
          fontSize: '1rem',
          fontFamily: 'Normal',
          fontWeight: 400,
          lineHeight: 1,
          transform: 'scale(0.75)',
        }}
      >
        {label}
      </Box>

      {/* Value of Label */}
      <Box
        component="label"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '0 6px',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '1rem',
          fontFamily: 'Normal',
          fontWeight: 400,
          lineHeight: 1,
          transform: 'translateY(-50%) scale(0.75)',
        }}
      >
        {label}
      </Box>
      <RangePicker
        style={{
          maxWidth: '380px',
        }}
        allowClear={[true, true]}
        value={dateRangeOnCalendar}
        disabledTime={() => ({
          disabledHours: () => [],
          disabledMinutes: () => range(0, 60),
        })}
        disabled={disabled}
        bordered={false}
        format={dateFormat}
        locale={antDesignLocale}
        onChange={onChangeRangePicker}
        timeFormat="HH:mm"
        showTime={{
          showHour: true,
          showMinute: true,
          defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
        }}
        allowEmpty={[true, true]}
        {...rest}
      />
    </Box>
  );
};

export default CampaignFilterWrapper;
