import React from 'react';
import { AutocompleteInput, ReferenceInput, useLocale } from 'react-admin';
import PropTypes from 'prop-types';
import SettingStep from '../../../bet-setting-default/components/create/SettingStep';

const Preview = ({ baseBets }) => {
  const locale = useLocale();

  return (
    <>
      <ReferenceInput
        variant="outlined"
        source="group.id"
        reference="group"
        enableGetChoices={() => false}
        disabled
        label="resources.group.name"
      >
        <AutocompleteInput />
      </ReferenceInput>
      <ReferenceInput
        variant="outlined"
        source="brand.id"
        reference="brand"
        enableGetChoices={() => false}
        disabled
        label="resources.brand.name"
      >
        <AutocompleteInput />
      </ReferenceInput>
      <ReferenceInput
        variant="outlined"
        source="game.id"
        reference="game"
        enableGetChoices={() => false}
        disabled
        label="resources.game.name"
      >
        <AutocompleteInput
          optionText={`name.${locale}`}
        />
      </ReferenceInput>
      <ReferenceInput
        variant="outlined"
        source="currency.id"
        reference="currency"
        enableGetChoices={() => false}
        disabled
        label="resources.currency.name"
      >
        <AutocompleteInput />
      </ReferenceInput>
      <SettingStep
        disabled
        baseBets={baseBets}
      />
    </>
  );
};

Preview.propTypes = {
  baseBets: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Preview.defaultProps = {};

export default Preview;
