import { CACHE_PLAYER_CAMPAIGN_USAGE_BY_PAGE, UPDATE_CURRENT_PLAYER_CAMPAIGN_USAGE_PAGE } from '../root.actions';

export const cachePlayerCampaignUsageByPage = ({
  data, page,
}) => ({
  type: CACHE_PLAYER_CAMPAIGN_USAGE_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentPlayerCampaignUsagePage = data => ({
  type: UPDATE_CURRENT_PLAYER_CAMPAIGN_USAGE_PAGE,
  payload: data,
});
