/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FunctionField, useLocale } from 'react-admin';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { useReloadContext } from '../../base/context/reload/reload.context';
import { useSchema } from '../../base/hooks';
import ActionLink from './components/action.link';
import DefaultChip from '../../base/components/DefaultChip';

const useStyles = makeStyles({
  listWrapper: {
    '& table tr td:last-child': {
      width: 180,
      maxWidth: 180,
    },
  },
});

function GameList(props) {
  const styles = useStyles();
  const locale = useLocale();
  const { setValuesContext } = useReloadContext();
  const [requestFields, setRequestFields] = useState([]);

  useEffect(
    () => () => {
      setValuesContext({});
    },
    [],
  );

  const {
    api, ref,
  } = useSchema();

  useEffect(() => {
    if (api) {
      const { paths } = api;

      const basePath = '/game';
      const target = `${basePath}/{id}`;
      let requestRef = paths?.[target]?.patch?.requestBody?.content?.['application/json']?.schema;

      requestRef = requestRef.$ref || requestRef.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

      const requestComponent = ref.get(requestRef);

      const { properties } = requestComponent;

      const writableFields = Object.keys(properties);

      setRequestFields(writableFields);
    }
  }, [api]);

  return (
    <>
      <WealthListGuesser
        {...props}
        excludeFilterInputs={['isAvailable']}
        extraActions={(
          <ActionLink
            label="ra.field.action"
            requestFields={requestFields}
            {...props}
          />
        )}
        excludeFields={['enabled', 'isAvailable', 'baseBet']}
        fieldOrder={['id', 'created', 'updated', `name.${locale}`, `desc.${locale}`, 'gameId', 'gameType.id', `preview.${locale}.blobUrl`, 'baseBet', 'gameLogicUrl', 'playUrl']}
        className={styles.listWrapper}
      >
        <FunctionField
          source="baseBet"
          render={record => {
            if (Array.isArray(record.multiBaseBets)) {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <DefaultChip />
                  <Box component="span">{record.baseBet}</Box>
                </Box>
              );
            }
            return record.baseBet;
          }}
        />
      </WealthListGuesser>
    </>
  );
}

export default GameList;
