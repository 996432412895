/* eslint-disable */
import { Box, makeStyles } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';
import clsx from 'clsx';
import AddableWheelPicker from './addable-wheel-picker';
import { roundToTwo } from '../../../../services/util/formatNumber';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  columnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  baseBetContainer: {
    fontWeight: 'bold',
    borderRadius: 4,
    background: '#F0F1F2',
    padding: 20,
    fontSize: 16,
    width: '100%',
  },
  totalBetContainer: {
    fontWeight: 'bold',
    borderRadius: '8px',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '12px 0',
    fontSize: 16,
    '&>*': {
      backgroundColor: '#F0F1F2',
      height: 40,
      width: '100%',
      justifyContent: 'center',
    },
  },
  detailBetLimitContainer: {
    borderRadius: 4,
    background: '#F0F1F2',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 7,
    fontSize: 16,
    color: 'rgba(141, 141, 141, 1)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  disabledDetailBetLimitContainer: {
    border: 'none',
    background: 'none',
    padding: 0,
    fontSize: 13,
  },
});

const SingleBaseBetSettings = ({
  baseBet,
  betSizes: defaultBetSizes,
  betLevels: defaultBetLevels,
  disabled,
  hiddenLimitRange,
  index,
  onAdd,
  onDelete,
  onDefault,
  ...props
}) => {
  const translate = useTranslate();
  const styles = useStyles();

  const betSizes = useMemo(
    () =>
      defaultBetSizes?.filter((item) => item.baseBet === baseBet || !item.baseBet).sort((a, b) => a.value - b.value) ||
      [],
    [baseBet, defaultBetSizes],
  );
  const betLevels = useMemo(
    () =>
      defaultBetLevels?.filter((item) => item.baseBet === baseBet || !item.baseBet).sort((a, b) => a.value - b.value) ||
      [],
    [baseBet, defaultBetLevels],
  );

  const [totalBetMin, totalBetMax] = useMemo(
    () => [
      roundToTwo(baseBet * betSizes[0]?.value * betLevels[0]?.value) || undefined,
      roundToTwo(baseBet * betSizes[betSizes.length - 1]?.value * betLevels[betLevels.length - 1]?.value) || undefined,
    ],
    [baseBet, betSizes, betLevels],
  );

  const [betSizeIndex, setBetSizeIndex] = useState(
    Math.max(
      betSizes.findIndex((item) => item.default),
      0,
    ),
  );
  const [betLevelIndex, setBetLevelIndex] = useState(
    Math.max(
      betLevels.findIndex((item) => item.default),
      0,
    ),
  );

  const totalBet = useMemo(
    () =>
      !betSizes[betSizeIndex]?.value || !betLevels[betLevelIndex]?.value
        ? undefined
        : roundToTwo(baseBet * betSizes[betSizeIndex]?.value * betLevels[betLevelIndex]?.value) || undefined,
    [betSizeIndex, betLevelIndex, betSizes, betLevels, baseBet],
  );

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: !hiddenLimitRange && disabled ? '1fr 1fr 1fr 1fr 0.5fr' : 'repeat(4, 1fr)',
        gap: 18,
        justifyContent: 'center',
      }}
      {...props}
    >
      <Box className={styles.columnCenter}>
        {index > 0 ? null : (
          <Box mb={2} fontWeight="bold">
            {translate('ra.field.baseBet')}
          </Box>
        )}
        <Box className={clsx(styles.columnCenter, styles.baseBetContainer)}>{baseBet}</Box>
      </Box>
      <Box>
        <AddableWheelPicker
          disabled={disabled}
          data={betSizes.map((item) => item.value)}
          defaultValue={betSizes.find((item) => item.default)?.value}
          wheelPickerProps={{
            title: index > 0 ? '' : translate('resources.bet-setting.fields.betSizes'),
            defaultSelection: betSizeIndex,
            updateSelection: setBetSizeIndex,
            onDelete: (value) => onDelete(value, 'betSizes'),
            onDefault: (value) => onDefault(value, 'betSizes'),
          }}
          scrollerId={`bet-level-${baseBet}`}
          addLabel={translate('ra.action.addBetLevel')}
          onAdd={(value) => onAdd(value, 'betSizes')}
        />
      </Box>
      <Box>
        <AddableWheelPicker
          disabled={disabled}
          data={betLevels.map((item) => item.value)}
          defaultValue={betLevels.find((item) => item.default)?.value}
          wheelPickerProps={{
            title: index > 0 ? '' : translate('resources.bet-setting.fields.betLevels'),
            defaultSelection: betLevelIndex,
            updateSelection: setBetLevelIndex,
            onDelete: (value) => onDelete(value, 'betLevels'),
            onDefault: (value) => onDefault(value, 'betLevels'),
          }}
          scrollerId={`bet-size-${baseBet}`}
          addLabel={translate('ra.action.addBetSize')}
          onAdd={(value) => onAdd(value, 'betLevels')}
        />
      </Box>
      <Box
        className={styles.columnCenter}
        sx={{
          '&>*:not(:last-child)': {
            marginBottom: 8,
          },
        }}
      >
        {index > 0 ? null : (
          <Box mb={2} fontWeight="bold">
            {translate('ra.field.totalBet')}
          </Box>
        )}
        <Box className={clsx(styles.columnCenter, styles.totalBetContainer)}>
          <Box className={styles.columnCenter}>{totalBet}</Box>
        </Box>
        {!hiddenLimitRange && !disabled && <DetailBetLimitRange totalBetMin={totalBetMin} totalBetMax={totalBetMax} />}
      </Box>
      {!hiddenLimitRange && disabled && (
        <Box display="flex" justifyContent="end" flexDirection="column" mb="14px">
          <DetailBetLimitRange totalBetMin={totalBetMin} totalBetMax={totalBetMax} disabled={disabled} />
        </Box>
      )}
    </Box>
  );
};

export const DetailBetLimitRange = ({ totalBetMin, totalBetMax, disabled, className }) => {
  const translate = useTranslate();
  const styles = useStyles();

  return (
    <>
      {[
        {
          value: totalBetMin,
          label: translate('ra.field.totalBetMin'),
        },
        {
          value: totalBetMax,
          label: translate('ra.field.totalBetMax'),
        },
      ].map((item, idx) => (
        <Box
          key={idx}
          className={clsx(
            styles.detailBetLimitContainer,
            {
              [styles.disabledDetailBetLimitContainer]: disabled,
            },
            className,
          )}
        >
          {item.label}
          <Box component="span" color="black" pl={1}>
            {item.value}
          </Box>
        </Box>
      ))}
    </>
  );
};

SingleBaseBetSettings.propTypes = {
  baseBet: PropTypes.number.isRequired,
  betSizes: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, default: PropTypes.bool, baseBet: PropTypes.number }),
  ).isRequired,
  betLevels: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, default: PropTypes.bool, baseBet: PropTypes.number }),
  ).isRequired,
  disabled: PropTypes.bool,
  hiddenLimitRange: PropTypes.bool,
  index: PropTypes.number,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onDefault: PropTypes.func,
};

export default SingleBaseBetSettings;
