/* eslint-disable */
import React from "react";
import WealthShowGuesser from "../../base/components/guesser/wealth-show.guesser";
import BetSettingSelector from "./components/show/bet-setting-selector";

const BetSettingDefaultShow = (props) => {
  return (
    <WealthShowGuesser
      excludeFields={['brand', 'group', 'betSizes', 'betLevels', 'totalBetLimit']}
      {...props}
    >
     <BetSettingSelector/>
    </WealthShowGuesser>
  );
};

export default BetSettingDefaultShow;
