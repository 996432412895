/* eslint-disable no-use-before-define, no-shadow */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Field, useForm, useField } from 'react-final-form';
import { Box, MenuItem, Select, useTheme } from '@material-ui/core';
import { useLocale, useTranslate } from 'react-admin';
import { SearchOutlined } from '@material-ui/icons';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import { useTableStyles } from '../../utils';
import AntTable from '../../../../../base/components/antd/AntdTable';
import resourceSlug from '../../../../../constant/resource-slug';
import { sanitizeObject } from '../../../../../services/util';

const GameFreeSpinConfigTable = ({
  games, currencies, brandId, gameConfig,
}) => {
  const [betSettings, setBetSettings] = useState([]);
  const form = useForm();
  const availableCurrencies = useSelector(({ admin }) => admin.resources[resourceSlug.BRAND].data[brandId]?.currencies || []);
  const availableGames = useField('availableGames')?.input?.value || [];

  const handleFetchBetSettings = async () => {
    try {
      const response = await axios.post('/api/brand/get-bet-settings', {
        brandId,
        gameIds: games.map(game => game.id),
        currencyIds: currencies.map(currency => currency.id),
      });
      const refactoredData = response.data.data.map(item => {
        const initialRecord = gameConfig?.find(config => config.game?.id === item.gameId && config.currency?.id === item.currencyId);
        return sanitizeObject({
          game: availableGames.find(game => game.id === item.gameId),
          currency: availableCurrencies.find(currency => currency.id === item.currencyId),
          id: initialRecord?.id,
          ...(item.betScales.find(betScale => (initialRecord ? initialRecord.total === betScale.total : betScale.default))),
          ...item,
        });
      });
      setBetSettings(refactoredData);
      form.change('gameConfig', refactoredData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!availableGames.length || !availableCurrencies.length || !brandId || !games.length || !currencies.length) return;
    handleFetchBetSettings();
  }, [games, currencies, brandId, availableGames, availableCurrencies]);

  return !!betSettings.length && (
    <Field
      name="gameConfig"
      component={ConfigTable}
      initialValue={betSettings}
    />
  );
};

const ConfigTable = ({
  input: {
    value: data, onChange,
  },
}) => (
  <FreeSpinConfigTable
    data={data}
    onChange={onChange}
  />
);

const FreeSpinConfigTable = ({
  data, onChange,
}) => {
  const classes = useTableStyles();
  const t = useTranslate();
  const locale = useLocale();
  const searchInput = useRef(null);
  const muiTheme = useTheme();

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm,
    }) => (
      <Box
        p="8px"
        width={250}
        borderRadius="6px"
        onKeyDown={e => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={t('ra.action.search')}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({
              closeDropdown: false,
            });
          }}
          allowClear
        />
      </Box>
    ),
    filterIcon: filtered => (
      <Box
        component={SearchOutlined}
        sx={{
          color: filtered ? muiTheme.palette.primary.main : undefined,
        }}
      />
    ),
    onFilter: (value, record) => get(record, dataIndex, '').toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.focus(), 100);
      }
    },
  });

  const columns = [
    {
      title: t('resources.game.name'),
      key: 'game',
      width: '33%',
      className: classes.bold,
      render: (_, record) => record?.game?.name?.[locale],
      ...getColumnSearchProps(`game.name.${locale}`),
    },
    {
      title: t('resources.currency.name'),
      key: 'currency',
      width: '33%',
      className: classes.bold,
      render: (_, record) => record?.currency.name,
      ...getColumnSearchProps('currency.name'),
    },
    {
      title: t('resources.campaign.fields.bet-amount'),
      key: 'betAmount',
      dataIndex: 'total',
      width: '34%',
      render: (total, record) => (
        <Select
          value={total}
          onChange={e => {
            const newData = data.map(item => {
              if (item.gameId === record.gameId && item.currencyId === record.currencyId) {
                const betScaleValues = record.betScales?.find(i => i.total === e.target.value);
                return {
                  ...item,
                  ...betScaleValues,
                };
              }
              return item;
            });

            onChange?.(newData);
          }}
          variant="outlined"
          className={classes.betAmountSelector}
        >
          {record.betScales?.map(betScale => (
            <MenuItem
              key={betScale.total}
              value={betScale.total}
            >
              {betScale.total}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <Box className={classes.table}>
      <AntTable
        columns={columns}
        dataSource={data}
        rowKey={record => `${record.gameId}+${record.currencyId}`}
      />
    </Box>
  );
};

export default GameFreeSpinConfigTable;
