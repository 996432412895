/* eslint-disable */
import React from "react";

import WealthShowGuesser from "../../base/components/guesser/wealth-show.guesser";
import BetSettingSelector from "../bet-setting-default/components/show/bet-setting-selector";

const BetSettingShow = (props) => {
  return (
    <WealthShowGuesser    excludeFields={[ 'betSizes', 'betLevels', 'totalBetLimit']} {...props}>
      <BetSettingSelector/>
    </WealthShowGuesser>
  );
};

export default BetSettingShow;
