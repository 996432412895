/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { useTranslate } from 'ra-core';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEditController } from 'react-admin';
import BasicInfoStep from './components/create/BasicInfoStep';
import PreviewStep from './components/create/PreviewStep';
import MultiStepForm from '../../base/components/bet-setting/multi-step-form';
import { Edit } from '../../base/components/ra/views';
import SettingStep from '../bet-setting-default/components/create/SettingStep';
import { useAuthUser, useUpdateCustom } from '../../base/hooks';
import resourceSlug from '../../constant/resource-slug';
import { roundToTwo } from '../../services/util/formatNumber';

const BetSettingEdit = props => {
  const user = useAuthUser();
  const translate = useTranslate();
  const [currentStep, setCurrentStep] = useState(0);
  const [baseBets, setBaseBets] = useState([]);
  const steps = [
    translate('resources.bet-setting.steps.basicInfo'),
    translate('resources.bet-setting.steps.setting'),
    translate('resources.bet-setting.steps.preview'),
  ];

  const { record } = useEditController(props);
  const { update } = useUpdateCustom({
    resource: resourceSlug.BET_SETTING,
    resourceTranslated: translate(`resources.${resourceSlug.BET_SETTING}.name`),
  });

  const onSave = values => {
    const {
      game, currency, betSizes, betLevels, group, brand,
    } = values;

    const newBetSizes = betSizes.sort((a, b) => a.value - b.value);
    const newBetLevels = betLevels.sort((a, b) => a.value - b.value);
    const sortedBaseBets = baseBets.sort((a, b) => a - b);

    const totalBetMin = roundToTwo(sortedBaseBets[0] * newBetSizes[0].value * newBetLevels[0].value);
    const totalBetMax = roundToTwo(
      sortedBaseBets[sortedBaseBets.length - 1]
        * newBetSizes[newBetSizes.length - 1].value
        * newBetLevels[newBetLevels.length - 1].value,
    );

    const payload = {
      id: record?.id,
      data: {
        brand,
        game,
        currency,
        totalBetLimit: [totalBetMin, totalBetMax],
        betSizes,
        betLevels,
      },
    };

    if (!user.group?.id) {
      payload.data.group = group;
    }

    update({
      resource: resourceSlug.BET_SETTING,
      payload,
    });
  };

  return (
    <Edit {...props}>
      <MultiStepForm
        onStepChange={setCurrentStep}
        confirmationMessage={{
          title: '',
          content: translate('resources.bet-setting-default.editConfirmationMessage'),
        }}
        currentStep={currentStep}
        steps={steps}
        fieldsInStep={{
          0: ['game.id', 'currency.id'],
          1: ['betSizes', 'betLevels'],
        }}
        onCustomSave={onSave}
        {...props}
      >
        <Box
          sx={{
            minWidth: '95%',
            margin: 'auto',
          }}
        >
          {
            {
              0: <BasicInfoStep
                disabled
                setBaseBets={setBaseBets}
              />,
              1: <SettingStep baseBets={baseBets} />,
              2: <PreviewStep baseBets={baseBets} />,
            }[currentStep]
          }
        </Box>
      </MultiStepForm>
    </Edit>
  );
};

BetSettingEdit.propTypes = {
  resource: PropTypes.string.isRequired,
};

BetSettingEdit.defaultProps = {};

export default BetSettingEdit;
