import React from 'react';
import { FormDataConsumer, useTranslate } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import GamesInput from './GamesInput';
import CurrenciesInput from './CurrenciesInput';
import GameFreeSpinConfigTable from './GameFreeSpinConfigTable';
import RewardRulesInput from './RewardRulesInput';
import StockInput from './StockInput';

const DetailsInfoStep = ({ isEditForm }) => {
  const { initialValues } = useFormState();
  const cantEdit = isEditForm && initialValues?.isActive;
  const t = useTranslate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 28,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gap: 12,
        }}
      >
        <Typography variant="h3">{t('resources.campaign.reward-settings')}</Typography>
        <Box>
          <StockInput
            source="stock"
            label="resources.campaign.fields.stock"
            disabled={cantEdit}
            isEditForm={isEditForm}
          />
          <FormDataConsumer>
            {({
              formData, ...rest
            }) => (
              <GamesInput
                required
                brandId={formData.brandId}
                groupId={formData.groupId}
                disabled={cantEdit}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({
              formData, ...rest
            }) => (
              <CurrenciesInput
                required
                brandId={formData.brandId}
                disabled={cantEdit}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({
              formData, ...rest
            }) => !!formData.games?.length && !!formData.currencies?.length && (
              <GameFreeSpinConfigTable
                games={formData.games}
                currencies={formData.currencies}
                brandId={formData.brandId}
                gameConfig={formData.gameConfig}
                {...rest}
              />
            )}
          </FormDataConsumer>

        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gap: 12,
        }}
      >
        <Typography variant="h3">{t('resources.campaign.reward-eligibility')}</Typography>
        <Box>
          <RewardRulesInput
            source="rewardRules"
            disabled={cantEdit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DetailsInfoStep;
