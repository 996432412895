/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTranslate } from 'react-admin';
import { useCampaignSteps } from './index';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '500px',
    margin: 'auto',
  },
});

const CampaignStepper = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { activeStep } = useCampaignSteps();

  const steps = useMemo(
    () => [t('resources.campaign.basic-info'), t('resources.campaign.details-info'), t('resources.campaign.preview')],
    [t],
  );

  return (
    <Stepper
      className={classes.root}
      activeStep={activeStep}
    >
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CampaignStepper;
