/* eslint-disable */
import React from 'react';
import { ReferenceField, SelectField, TextField, useTranslate } from 'react-admin';
import resourceSlug from '../../../../constant/resource-slug';
import { LIST_REPORTS } from '../../report-sales-and-profit.list';
import CustomReportTable from '../../../../base/components/reports/CustomReportTable';
import TranslatableField from '../../../../base/components/ra/fields/translatable.field';
import BaseCurrencyField from '../../../../base/components/reports/ReportTable/BaseCurrencyField';
import { useReportContext } from '../../../../base/components/reports/ReportLayout';
import { REPORT_TYPES } from '../../../../constant';
import { CAMPAIGN_ISSUERS } from '../../../../constant/campaign';

const MarketingTable = () => {
  const translate = useTranslate();
  const { appliedFilters } = useReportContext();

  return (
    <CustomReportTable
      title={translate('resources.report.marketing')}
      reportType={REPORT_TYPES.MARKETING}
      reportList={LIST_REPORTS}
      resource={resourceSlug.REPORT_SALES_AND_PROFIT}
    >
      <ReferenceField link="show" source="group" reference="group" label="resources.group.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField link="show" source="brand" reference="brand" label="resources.brand.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="resources.report.fields.campaignCode" source="campaignCode" sortable={false} />
      <TextField label="resources.report.fields.campaignName" source="campaignName" sortable={false} />
      <SelectField
        source="issuer"
        label="resources.campaign.fields.issuer"
        choices={Object.values(CAMPAIGN_ISSUERS).map((issuer) => ({
          id: issuer,
          name: translate(`resources.campaign.issuer.${issuer}`),
        }))}
        sortable={false}
      />
      <TranslatableField label="resources.report.fields.game" source="gameName" />
      <TextField label="resources.report.fields.gameType" source="gameType" />
      <TextField label="resources.bet.fields.channel" source="channel" />
      <TextField label="resources.report.fields.betType" source="betType" />
      <TextField label="resources.report.fields.betCount" source="betCount" sortable />
      <TextField label="resources.report.fields.playerCount" source="playerCount" sortable={false} />
      <TextField label="resources.report.fields.currencyType" source="currencyType" sortable={false} />
      <ReferenceField source="currency" reference="currency" label="resources.currency.name" link="show">
        <TextField source="code" />
      </ReferenceField>
      {Boolean(appliedFilters.consolidated) && <BaseCurrencyField label="resources.report.fields.baseCurrency" />}
      <TextField label="resources.report.fields.stakes" source="stakes" sortable />
      <TextField label="resources.report.fields.wonAmount" source="wonAmount" sortable />
    </CustomReportTable>
  );
};

export default MarketingTable;
