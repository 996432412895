/* eslint-disable no-plusplus, consistent-return */
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { CAMPAIGN_STATUS } from '../../../constant/campaign';
import { sanitizeObject } from '../../../services/util';

export const validateCampaign = values => {
  const errors = {};
  if (!values.campaignName) {
    errors.campaignName = 'ra.validation.required';
  } else if (values.campaignName.trim().length > 64) {
    errors.campaignName = {
      message: 'ra.validation.maxLength',
      args: {
        max: 64,
      },
    };
  }

  if (!values.campaignCode) {
    errors.campaignCode = 'ra.validation.required';
  } else if (values.campaignCode.trim().length > 32) {
    errors.campaignCode = {
      message: 'ra.validation.maxLength',
      args: {
        max: 32,
      },
    };
  }

  if (!values.campaignType) {
    errors.campaignType = 'ra.validation.required';
  }

  if (!values.issuer) {
    errors.issuer = 'ra.validation.required';
  }

  if (!values.groupId) {
    errors.groupId = 'ra.validation.required';
  }

  if (!values.brandId) {
    errors.brandId = 'ra.validation.required';
  }

  if (!values.expDate?.every(item => item)) {
    errors.expDate = 'ra.validation.required';
  } else if (moment(values.expDate[1]).diff(moment(values.expDate[0]), 'years', true) > 1) {
    errors.expData = 'resources.campaign.validation.exp-date-max-a-year';
  }

  if (!values.freeSpinExpireIn) {
    errors.freeSpinExpireIn = 'ra.validation.required';
  }

  if (values.description && values.description.trim().length > 255) {
    errors.description = {
      message: 'ra.validation.maxLength',
      args: {
        max: 255,
      },
    };
  }

  let totalFreeSpinQuantity = 0;
  const stock = values.stock ?? Infinity;

  if (!values.rewardRules || !values.rewardRules.length) {
    errors.rewardRules = 'ra.validation.required';
  } else if (values.rewardRules.length > 5) {
    errors.rewardRules = {
      message: 'ra.validation.maxLength',
      args: {
        max: 5,
      },
    };
  } else {
    const previousRules = [];
    errors.rewardRules = values.rewardRules.map(rule => {
      const childErrors = {};
      if (!rule || !rule.freeSpinReward) {
        childErrors.freeSpinReward = {
          message: 'ra.validation.minValue',
          args: {
            min: 1,
          },
        };
      } else {
        totalFreeSpinQuantity += rule.freeSpinReward;
      }

      if (typeof rule?.minimumSpins !== 'number') {
        childErrors.minimumSpins = 'ra.validation.required';
      }
      if (typeof rule?.consecutiveLoginDays !== 'number') {
        childErrors.consecutiveLoginDays = 'ra.validation.required';
      } else if (values.expDate?.[0] && values.expDate?.[1]
        && rule.consecutiveLoginDays > Math.round(moment(values.expDate[1]).diff(moment(values.expDate[0]), 'days', true))) {
        childErrors.consecutiveLoginDays = 'resources.campaign.validation.not-gt-exp-date';
        errors.expDate = 'resources.campaign.validation.not-lt-consecutive-days';
      }
      if (previousRules.find(previousRule => previousRule.minimumSpins === rule?.minimumSpins && previousRule.consecutiveLoginDays === rule?.consecutiveLoginDays)) {
        childErrors.minimumSpins = 'resources.campaign.validation.do-not-duplicated';
        childErrors.consecutiveLoginDays = 'resources.campaign.validation.do-not-duplicated';
      }

      if (typeof rule?.minimumSpins === 'number' && typeof rule?.consecutiveLoginDays === 'number') {
        previousRules.push(rule);
      }

      return childErrors;
    });
  }

  if (totalFreeSpinQuantity > stock) {
    errors.stock = 'resources.campaign.validation.not-enough-stock';
  }

  if (!values.games || !values.games.length) {
    errors.games = 'ra.validation.required';
  }

  if (!values.currencies || !values.currencies.length) {
    errors.currencies = 'ra.validation.required';
  }

  return errors;
};

export const formatCampaignData = values => ({
  campaign: {
    name: values.campaignName.trim(),
    campaignCode: values.campaignCode.trim(),
    campaignType: values.campaignType,
    issuer: values.issuer,
    brandId: values.brandId,
    description: values.description?.trim(),
    stock: values.stock ?? null,
    freeSpinExpireIn: values.freeSpinExpireIn,
    freeSpinExpireUnit: values.freeSpinExpireUnit,
    status: values.isActive ? CAMPAIGN_STATUS.ACTIVE : CAMPAIGN_STATUS.INACTIVE,
    startTime: moment(values.expDate[0]).toISOString(),
    endTime: moment(values.expDate[1]).toISOString(),
  },
  gameConfig: values.gameConfig.map(config => (sanitizeObject({
    gameId: config.game.id,
    baseBet: config.baseBet,
    betSize: config.betSize,
    betLevel: config.betLevel,
    currencyId: config.currency.id,
    id: config.id,
  }))),
  rewardRules: values.rewardRules,
});

export const parseCampaignData = values => ({
  id: values.id,
  campaignName: values.name,
  campaignCode: values.campaignCode,
  campaignType: values.campaignType,
  expDate: [moment(values.startTime), moment(values.endTime)],
  brandId: values.brandId,
  groupId: values.groupId,
  issuer: values.issuer,
  isActive: values.status === CAMPAIGN_STATUS.ACTIVE,
  description: values.description,
  freeSpinExpireIn: values.freeSpinExpireIn,
  freeSpinExpireUnit: values.freeSpinExpireUnit,
  stock: values.stock,
  rewardRules: values.rewardRules || [{
    consecutiveLoginDays: undefined,
    freeSpinReward: undefined,
    minimumSpins: undefined,
  }],
  gameConfig: values.gameConfigs?.map(config => ({
    game: {
      id: config.gameId,
    },
    currency: {
      id: config.currencyId,
    },
    total: config.betAmount,
    baseBet: config.baseBet,
    betSize: config.betSize,
    betLevel: config.betLevel,
    id: config.id,
  })),
  games: values.gameConfigs?.reduce((games, config) => {
    if (!games.find(game => game.id === config.gameId)) {
      games.push({
        id: config.gameId,
      });
    }
    return games;
  }, []),
  currencies: values.gameConfigs?.reduce((currencies, config) => {
    if (!currencies.find(currency => currency.id === config.currencyId)) {
      currencies.push({
        id: config.currencyId,
      });
    }
    return currencies;
  }, []),
});

export const parseClonedCampaignData = values => ({
  campaignName: values.name,
  campaignType: values.campaignType,
  expDate: moment(values.startTime).isBefore(moment().endOf('day')) ? [] : [moment(values.startTime), moment(values.endTime)],
  brandId: values.brandId,
  groupId: values.groupId,
  issuer: values.issuer,
  isActive: false,
  description: values.description,
  freeSpinExpireIn: values.freeSpinExpireIn,
  freeSpinExpireUnit: values.freeSpinExpireUnit,
  stock: values.stock,
  rewardRules: values.rewardRules?.map(item => ({
    minimumSpins: item.minimumSpins,
    freeSpinReward: item.freeSpinReward,
    consecutiveLoginDays: item.consecutiveLoginDays,
  })),
  gameConfig: values.gameConfigs?.map(config => ({
    game: {
      id: config.gameId,
    },
    currency: {
      id: config.currencyId,
    },
    total: config.betAmount,
    betSize: config.betSize,
    betLevel: config.betLevel,
    baseBet: config.baseBet,
  })),
  games: values.gameConfigs?.reduce((games, config) => {
    if (!games.find(game => game.id === config.gameId)) {
      games.push({
        id: config.gameId,
      });
    }
    return games;
  }, []),
  currencies: values.gameConfigs?.reduce((currencies, config) => {
    if (!currencies.find(currency => currency.id === config.currencyId)) {
      currencies.push({
        id: config.currencyId,
      });
    }
    return currencies;
  }, []),
});

export const useTableStyles = makeStyles(theme => ({
  betAmountSelector: {
    width: 120,
  },
  bold: {
    fontWeight: 600,
  },
  table: {
    "& [class*='ant-table-filter-trigger']:hover": {
      background: 'transparent',
    },
    '& .ant-table-thead .ant-table-cell': {
      color: theme.palette.grey[600],
    },
  },
  closeButton: {
    '& svg': {
      width: 16,
      height: 16,
    },

  },
}));

export const useTableListStyles = makeStyles(theme => ({
  root: {
    "& [class*='Paper']": {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
    },
  },
  wrapper: {
    margin: '16px 28px 28px',
  },
}));
