/* eslint-disable no-restricted-globals, no-shadow */
import React, { useState } from 'react';
import { useTranslate } from 'ra-core';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEditController } from 'react-admin';
import BasicInfoStep from './components/create/BasicInfoStep';
import SettingStep from './components/create/SettingStep';
import PreviewStep from './components/create/PreviewStep';
import MultiStepForm from '../../base/components/bet-setting/multi-step-form';
import { Edit } from '../../base/components/ra/views';
import { useUpdateCustom } from '../../base/hooks';
import { roundToTwo } from '../../services/util/formatNumber';

const BetSettingDefaultEdit = props => {
  const { resource } = props;
  const { record } = useEditController(props);
  const translate = useTranslate();
  const [currentStep, setCurrentStep] = useState(0);
  const [baseBets, setBaseBets] = useState([]);

  const steps = [
    translate('resources.bet-setting.steps.basicInfo'),
    translate('resources.bet-setting.steps.setting'),
    translate('resources.bet-setting.steps.preview'),
  ];

  const { update } = useUpdateCustom({
    resource,
    resourceTranslated: translate('resources.betSettingDefault.name'),
  });

  const onSave = values => {
    const {
      game, currency, betSizes, betLevels,
    } = values;

    const newBetSizes = betSizes.sort((a, b) => a.value - b.value);
    const newBetLevels = betLevels.sort((a, b) => a.value - b.value);
    const sortedBaseBets = baseBets.sort((a, b) => a - b);

    const totalBetMin = roundToTwo(sortedBaseBets[0] * newBetSizes[0].value * newBetLevels[0].value);
    const totalBetMax = roundToTwo(
      sortedBaseBets[sortedBaseBets.length - 1]
        * newBetSizes[newBetSizes.length - 1].value
        * newBetLevels[newBetLevels.length - 1].value,
    );

    update({
      resource,
      payload: {
        id: record?.id,
        data: {
          game,
          currency,
          totalBetLimit: [totalBetMin, totalBetMax],
          betSizes,
          betLevels,
        },
      },
    });
  };

  return (
    <Edit {...props}>
      <MultiStepForm
        onStepChange={setCurrentStep}
        confirmationMessage={{
          title: '',
          content: translate('resources.bet-setting-default.editConfirmationMessage'),
        }}
        currentStep={currentStep}
        steps={steps}
        fieldsInStep={{
          0: ['game.id', 'currency.id'],
          1: ['betSizes', 'betLevels'],
        }}
        onCustomSave={onSave}
        {...props}
      >
        <Box
          sx={{
            minWidth: '95%',
            margin: 'auto',
          }}
        >
          {
            {
              0: <BasicInfoStep
                disabled
                setBaseBets={setBaseBets}
              />,
              1: <SettingStep baseBets={baseBets} />,
              2: <PreviewStep baseBets={baseBets} />,
            }[currentStep]
          }
        </Box>
      </MultiStepForm>
    </Edit>
  );
};

BetSettingDefaultEdit.propTypes = {
  resource: PropTypes.string.isRequired,
};

BetSettingDefaultEdit.defaultProps = {};

export default BetSettingDefaultEdit;
