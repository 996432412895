/* eslint-disable react/require-default-props, react/prop-types, import/no-named-as-default */
import React from 'react';
import { SimpleForm, Toolbar, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Box, Step, StepLabel, Stepper, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { WealthToolbar } from '../guesser/wealth-toolbar';
import Button from '../ra/buttons/Button';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
  previousButton: {
    background: 'rgba(0, 0, 0, 0.38)',
  },
});

const validateFormValueByStep = (validationFields, form) => {
  const fieldsValid = validationFields?.length
    ? validationFields?.every(fieldName => {
      const fieldState = form?.getFieldState(fieldName);
      const isValid = fieldState?.valid;
      const isDirty = fieldState?.dirty;
      return isValid || !isDirty;
    })
    : true;
  return fieldsValid;
};

const LastStepToolbar = ({
  title, content, onStepChange, currentStep, onCustomSave,
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values } = useFormState();

  return (
    <WealthToolbar
      onCustomSave={() => onCustomSave(values)}
      alwaysEnableSaveButton
      customChildren={(
        <Button
          className={classes.previousButton}
          onClick={() => onStepChange(currentStep - 1)}
          label={translate('ra.action.previous')}
        />
      )}
      confirmDialogInfo={{
        title,
        content,
      }}
      isAcl
    />
  );
};

const CommonStepToolbar = ({
  fieldsInStep, currentStep, onStepChange,
}) => {
  const form = useForm();
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      {currentStep !== 0 && (
        <Button
          className={classes.previousButton}
          onClick={() => onStepChange(currentStep - 1)}
          label={translate('ra.action.previous')}
        />
      )}
      <Button
        disabled={!validateFormValueByStep(fieldsInStep[currentStep], form)}
        onClick={() => onStepChange(currentStep + 1)}
        label={translate('ra.action.next')}
      />
    </Toolbar>
  );
};

const MultiStepForm = ({
  children,
  currentStep,
  onCustomSave,
  onStepChange,
  fieldsInStep,
  confirmationMessage,
  steps,
  ...otherProps
}) => {
  const totalStep = steps.length;
  return (
    <SimpleForm
      redirect="show"
      {...otherProps}
      toolbar={
        currentStep === totalStep - 1 ? (
          <LastStepToolbar
            {...confirmationMessage}
            onCustomSave={onCustomSave}
            onStepChange={onStepChange}
            currentStep={currentStep}
          />
        ) : (
          <CommonStepToolbar
            fieldsInStep={fieldsInStep}
            currentStep={currentStep}
            onStepChange={onStepChange}
          />
        )
      }
    >
      <Box
        sx={{
          minWidth: 500,
          margin: '0 auto 24px',
        }}
      >
        <Stepper activeStep={currentStep}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {children}
    </SimpleForm>
  );
};

MultiStepForm.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCustomSave: PropTypes.func,
  onStepChange: PropTypes.func.isRequired,
  fieldsInStep: PropTypes.shape({
    [PropTypes.number]: PropTypes.arrayOf(PropTypes.string),
  }),
  confirmationMessage: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
};

export default MultiStepForm;
