/* eslint-disable react/no-array-index-key */
import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { FormDataConsumer, useTranslate } from 'react-admin';
import moment from 'moment';
import { useSelector } from 'react-redux';

const PreviewStep = () => {
  const t = useTranslate();
  const theme = useTheme();
  const [brands, groups] = useSelector(({ admin }) => [admin.resources.brand.data, admin.resources.group.data]);

  return (
    <Box
      pt={3}
      pb={5}
    >
      <FormDataConsumer>
        {({ formData }) => [
          {
            title: t('resources.campaign.fields.name'),
            value: formData.campaignName?.trim(),
          },
          {
            title: t('resources.campaign.fields.code'),
            value: formData.campaignCode?.trim(),
          },
          {
            title: t('resources.campaign.fields.type'),
            value: t(`resources.campaign.type.${formData.campaignType}`),
          },
          {
            title: t('resources.campaign.fields.exp-date'),
            value: formData.expDate?.map(d => moment(d).format('MM/DD/YYYY HH')).join(' - '),
          },
          {
            title: t('resources.campaign.fields.issuer'),
            value: t(`resources.campaign.issuer.${formData.issuer}`),
          },
          {
            title: t('resources.campaign.fields.group'),
            value: brands[formData.brandId]?.name,
          },
          {
            title: t('resources.campaign.fields.brand'),
            value: groups[formData.groupId]?.name,
          },
          {
            title: t('resources.campaign.fields.desc'),
            value: formData.description?.trim(),
          },
        ].map(({
          title, value,
        }, index) => (
          <Box
            key={index}
            display="grid"
            gridTemplateColumns="200px 1fr"
            maxWidth={{
              md: 700,
            }}
            mx="auto"
            mb={{
              xs: 3,
              lg: 4.5,
            }}
            sx={{
              '&>*:first-child': {
                color: theme.palette.grey[500],
              },
            }}
          >
            <Typography variant="h4">{title}</Typography>
            <Typography variant="h4">{value}</Typography>
          </Box>
        ))}
      </FormDataConsumer>
    </Box>
  );
};

export default PreviewStep;
