import React, { useEffect } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useForm, useField } from 'react-final-form';
import { AutocompleteArrayInput } from '../../../../../base/components/ra/inputs';

const GameInputs = ({
  brandId, groupId, ...otherProps
}) => {
  const t = useTranslate();
  const locale = useLocale();
  const form = useForm();
  const games = useField('availableGames')?.input?.value || [];

  useEffect(() => {
    if (!brandId || !groupId) return;
    axios.get(`api/campaign/available-games?brandId=${brandId}&groupId=${groupId}`).then(({ data }) => {
      form.change('availableGames', data?.data || []);
    });
  }, [brandId, groupId]);

  return (
    <AutocompleteArrayInput
      label={t('resources.game.name')}
      optionText={`name.${locale}`}
      resettable
      choices={games}
      translateChoice={false}
      format={data => data?.map(item => item.id)}
      parse={ids => ids?.map(id => games.find(game => game.id === id))}
      isRequired
      {...otherProps}
    />
  );
};

GameInputs.propTypes = {
  source: PropTypes.string,
};

GameInputs.defaultProps = {
  source: 'games',
};

export default GameInputs;
