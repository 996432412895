/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { AutocompleteInput, ReferenceInput, required, useDataProvider, useLocale, useRecordContext } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { useAuthUser } from '../../../../base/hooks';
import { fetchDefaultBetSetting, fetchGamesBeingAvailable } from '../../../../services/util/bet-setting.utils';

const BasicInfoStep = ({
  setBaseBets, disabled,
}) => {
  const dataProvider = useDataProvider();
  const user = useAuthUser();
  const form = useForm();
  const {
    values: {
      group, brand, game, currency, betSizes, betLevels,
    },
  } = useFormState();
  const [listCurrenciesInBrand, setListCurrenciesInBrand] = useState([]);

  const groupId = group?.id || user.group?.id;

  const previousGroupRef = useRef(group?.id);
  const previousBrandRef = useRef(brand?.id);

  useEffect(() => {
    if (group?.id === previousGroupRef.current) return;

    form.change('game.id', undefined);
    form.change('brand.id', undefined);
    form.change('currency.id', undefined);

    previousGroupRef.current = group?.id;
  }, [group?.id]);

  useEffect(() => {
    if (brand?.id === previousBrandRef.current) return;

    form.change('game.id', undefined);
    form.change('currency.id', undefined);

    previousGroupRef.current = brand?.id;
  }, [brand?.id]);

  useEffect(() => {
    if (!game?.id || !currency?.id || betSizes?.length || betLevels?.length) return;
    // Data of default bet setting
    fetchDefaultBetSetting(dataProvider, {
      gameId: game.id,
      currencyId: currency.id,
    })
      .then(data => {
        if (data.betSizes?.length && data.betLevels?.length) {
          form.change('betSizes', data.betSizes);
          form.change('betLevels', data.betLevels);
          form.change('defaultBaseBet', data.game.gameConfig?.baseBet);
        }
      })
      .catch(console.log);
  }, [game?.id, currency?.id, betSizes?.length, betLevels?.length]);

  return (
    <>
      {!user.group?.id && (
        <ReferenceInput
          label="resources.group.name"
          source="group.id"
          reference="group"
          filterToQuery={value => ({
            'name||$cont': value,
          })}
          fullWidth
          validate={required()}
          variant="outlined"
          perPage={100}
          isRequired
          disabled={disabled}
        >
          <AutocompleteInput
            optionText="name"
            resettable
          />
        </ReferenceInput>
      )}
      <ReferenceInput
        label="resources.brand.name"
        source="brand.id"
        reference="brand"
        filterToQuery={value => ({
          'name||$cont': value,
        })}
        filter={{
          'group.id': groupId,
        }}
        fullWidth
        validate={required()}
        variant="outlined"
        perPage={100}
        isRequired
        disabled={disabled || !groupId}
        enableGetChoices={() => true}
      >
        <BrandInput setListCurrenciesInBrand={setListCurrenciesInBrand} />
      </ReferenceInput>
      <GameInput
        setBaseBets={setBaseBets}
        disabled={disabled || !brand?.id}
      />
      <AutocompleteInput
        label="resources.currency.name"
        source="currency.id"
        disabled={disabled || !brand?.id}
        variant="outlined"
        choices={listCurrenciesInBrand}
        isRequired
      />
    </>
  );
};

const BrandInput = ({
  setListCurrenciesInBrand, ...props
}) => {
  const {
    input: { value },
    choices,
  } = props;

  useEffect(() => {
    const currencies = choices.find(choice => choice.id === value)?.currencies;

    if (currencies?.length) {
      setListCurrenciesInBrand(currencies);
    }
  }, [value, choices]);

  return (
    <AutocompleteInput
      resettable
      variant="outlined"
      fullWidth
      {...props}
    />
  );
};

const GameInput = ({
  setBaseBets, ...props
}) => {
  const locale = useLocale();
  const form = useForm();
  const [gameChoices, setGameChoices] = useState([]);
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const {
    values: {
      group, brand, game,
    },
  } = useFormState();
  const user = useAuthUser();
  const value = game?.id;
  const previousValueRef = useRef(value);
  const groupId = group?.id || user.group?.id;
  const brandId = brand?.id;

  useEffect(() => {
    if (!groupId || !brandId) {
      setGameChoices([]);
    } else {
      fetchGamesBeingAvailable(
        dataProvider,
        {
          groupId,
          brandId,
        },
        {
          pagination: {
            perPage: 1,
            page: 1,
          },
        },
      ).then(setGameChoices);
    }
  }, [groupId, brandId]);

  useEffect(() => {
    if (value === previousValueRef.current) return;

    form.change('betSizes', []);
    form.change('betLevels', []);

    previousValueRef.current = value;
  }, [value]);

  useEffect(() => {
    if (!gameChoices.length || !value) return;

    const selectedChoice = gameChoices?.find(choice => choice.id === value) || record.game?.gameConfig;
    const multiBaseBets = (selectedChoice?.gameConfig?.multiBaseBets || [selectedChoice?.gameConfig?.baseBet])?.sort(
      (a, b) => b - a,
    );
    form.change('defaultBaseBet', selectedChoice?.game?.gameConfig?.baseBet);

    if (multiBaseBets?.length) {
      setBaseBets(multiBaseBets);
    }
  }, [value, gameChoices]);

  return (
    <AutocompleteInput
      label="resources.game.name"
      source="game.id"
      optionText={`name.${locale}`}
      resettable
      variant="outlined"
      fullWidth
      optionValue="id"
      choices={gameChoices}
      {...props}
    />
  );
};

export default BasicInfoStep;
