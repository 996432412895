/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { AutocompleteInput, ReferenceInput, required, useLocale, useRecordContext } from 'react-admin';
import { useForm } from 'react-final-form';

const BasicInfoStep = ({
  disabled, setBaseBets,
}) => {
  const locale = useLocale();
  return (
    <>
      <ReferenceInput
        label="resources.game.name"
        source="game.id"
        reference="game"
        fullWidth
        validate={required()}
        variant="outlined"
        perPage={100}
        filterToQuery={value => ({
          [`name->>'$.${locale}'||$cont`]: value,
        })}
        isRequired
        disabled={disabled}
      >
        <GameInput setBaseBets={setBaseBets} />
      </ReferenceInput>
      <ReferenceInput
        label="resources.currency.name"
        source="currency.id"
        reference="currency"
        filter={{
          enabled: true,
        }}
        filterToQuery={value => ({
          'name||$cont': value,
        })}
        fullWidth
        validate={required()}
        variant="outlined"
        perPage={100}
        isRequired
        disabled={disabled}
      >
        <AutocompleteInput
          optionText="name"
          resettable
        />
      </ReferenceInput>
    </>
  );
};

const GameInput = ({
  setBaseBets, ...props
}) => {
  const locale = useLocale();
  const form = useForm();
  const record = useRecordContext();
  const {
    input: { value },
    choices,
  } = props;
  const previousValueRef = useRef(value);

  useEffect(() => {
    if (value === previousValueRef.current) return;

    form.change('betSizes', []);
    form.change('betLevels', []);

    previousValueRef.current = value;
  }, [value]);

  useEffect(() => {
    const selectedChoice = choices.find(choice => choice.id === value) || record.game?.gameConfig;
    const multiBaseBets = (selectedChoice?.multiBaseBets || [selectedChoice?.baseBet])?.sort((a, b) => b - a);
    form.change('defaultBaseBet', selectedChoice?.game?.gameConfig?.baseBet);

    if (multiBaseBets?.length) {
      setBaseBets(multiBaseBets);
    }
  }, [value]);

  return (
    <AutocompleteInput
      optionText={`name.${locale}`}
      resettable
      variant="outlined"
      fullWidth
      {...props}
    />
  );
};

export default BasicInfoStep;
