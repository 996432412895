import React, { useContext, useEffect } from 'react';
import { Box, Card, makeStyles } from '@material-ui/core';
import { FunctionField, ReferenceField, SelectField, TextField, useLocale, useShowController, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Divider } from 'antd';
import CommonShowWrapper from '../../base/components/customized/common-wrapper/CommonShowWrapper';
import { OtherFieldSection, ShowSection } from '../../base/components/guesser/wealth-show.guesser';
import Labeled from '../../base/components/ra/labeled';
import { DateField } from '../../base/components/ra/fields';
import { CAMPAIGN_ISSUERS, CAMPAIGN_STATUS, CAMPAIGN_TYPES } from '../../constant/campaign';
import CampaignStatus from './components/CampaignStatus';
import { NavigationContext } from '../../App';
import resourceSlug from '../../constant/resource-slug';
import { UUID_REGEX } from '../../constant';
import ArrayWithPaginationField from '../../base/components/ra/fields/arrayWithPagination.field';
import CampaignDetailsTabs, { CAMPAIGN_TABS, CampaignTab } from './components/CampaignDetailsTabs';

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    "& [class*='Card']": {
      padding: '0 0 16px',
      maxWidth: 1100,
    },
  },
  divider: {
    borderColor: theme.palette.grey[400],
    margin: '0 50px',
    width: 'calc(100% - 50px * 2)',
    minWidth: 'calc(100% - 50px * 2)',
  },
}));

const CampaignShow = props => {
  const t = useTranslate();
  const locale = useLocale();
  const classes = useStyles();
  const { record: campaign } = useShowController(props);

  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.campaign.details-title'),
        showSubLabel: false,
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.campaign.name'),
          link: `/${resourceSlug.CAMPAIGN}`,
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);

  return (
    <CommonShowWrapper
      defaultToolbarProps={{
        hasEdit: campaign?.status !== CAMPAIGN_STATUS.EXPIRED,
      }}
      {...props}
    >
      <Card>
        <CampaignDetailsTabs currentTab={CAMPAIGN_TABS.GENERAL_INFO}>
          <CampaignTab
            value={CAMPAIGN_TABS.GENERAL_INFO}
            index={CAMPAIGN_TABS.GENERAL_INFO}
          >
            <ShowSection title={t('resources.campaign.general-info')}>
              <TextField
                source="id"
                label="common.fields.id"
              />
              <TextField
                source="name"
                label="resources.campaign.fields.name"
              />
              <TextField
                source="campaignCode"
                label="resources.campaign.fields.code"
              />
              <SelectField
                source="campaignType"
                label="resources.campaign.fields.type"
                choices={Object.values(CAMPAIGN_TYPES).map(type => ({
                  id: type,
                  name: t(`resources.campaign.type.${type}`),
                }))}
              />
              <SelectField
                source="issuer"
                label="resources.campaign.fields.issuer"
                choices={Object.values(CAMPAIGN_ISSUERS).map(issuer => ({
                  id: issuer,
                  name: t(`resources.campaign.issuer.${issuer}`),
                }))}
              />
              <Labeled label="resources.campaign.fields.start-date">
                <DateField source="startTime" />
              </Labeled>
              <Labeled label="resources.campaign.fields.end-date">
                <DateField source="endTime" />
              </Labeled>
              <Labeled label="resources.campaign.fields.status"><CampaignStatus /></Labeled>
              <ReferenceField
                reference="group"
                source="groupId"
                label="common.fields.group"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                reference="brand"
                source="brandId"
                label="common.fields.brand"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                label="resources.campaign.fields.issued-exp-date"
                render={record => t(`resources.campaign.unit-content.${record.freeSpinExpireUnit}`, {
                  smart_count: record.freeSpinExpireIn,
                })}
              />
              <TextField
                source="stock"
                label="resources.campaign.fields.stock"
              />
              <TextField
                source="description"
                label="resources.campaign.fields.desc"
              />
            </ShowSection>
            <Divider
              dashed
              className={classes.divider}
            />
            <Box
              component={OtherFieldSection}
              p="32px 50px"
              title={t('resources.campaign.reward-eligibility')}
              classes={{
                root: classes.tableWrapper,
              }}
            >
              <ArrayWithPaginationField
                source="rewardRules"
                label={false}
              >
                <TextField
                  source="consecutiveLoginDays"
                  label="resources.campaign.fields.consecutive-login-days"
                  sortable={false}
                />
                <TextField
                  source="minimumSpins"
                  label="resources.campaign.fields.min-spins"
                  sortable={false}
                />
                <TextField
                  source="freeSpinReward"
                  label="resources.campaign.fields.free-spins"
                  sortable={false}
                />
              </ArrayWithPaginationField>
            </Box>

            <Divider
              dashed
              className={classes.divider}

            />
            <Box
              component={OtherFieldSection}
              p="32px 50px"
              title={t('resources.campaign.reward-settings')}
              classes={{
                root: classes.tableWrapper,
              }}
            >
              <ArrayWithPaginationField
                source="gameConfigs"
                label={false}
              >
                <ReferenceField
                  reference="game"
                  source="gameId"
                  label="resources.campaign.fields.game"
                  link="show"
                >
                  <TextField source={`name.${locale}`} />
                </ReferenceField>
                <ReferenceField
                  reference="currency"
                  source="currencyId"
                  label="resources.campaign.fields.currency"
                  link="show"
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField
                  label="resources.campaign.fields.bet-amount"
                  source="betAmount"
                  sortable={false}
                />
              </ArrayWithPaginationField>
            </Box>
          </CampaignTab>
        </CampaignDetailsTabs>

      </Card>
    </CommonShowWrapper>
  );
};

export default CampaignShow;
