/* eslint-disable */
// import { Loading, getResources } from 'react-admin';
// import React, { useMemo } from 'react';
// import { useSelector } from 'react-redux';
// import GamePerformanceReportFilters from './components/GamePerformanceReportFilters';
// import ReportLayout from '../../base/components/reports/ReportLayout';
// import GamePerformanceReportResults from './components/GamePerformanceReportResults';
// import { REPORT_TYPES } from '../../constant/resource-slug';

// export const LIST_REPORTS = [REPORT_TYPES.OVERALL, REPORT_TYPES.PREPAID, REPORT_TYPES.STANDARD];

// function ReportGamePerformanceList({ resource }) {
//   const originalResources = useSelector(getResources);

//   const resources = useMemo(() => originalResources, [originalResources?.length]);

//   if (!resources?.length) {
//     return null;
//   }

//   const currentResource = resources.filter((r) => r.name === resource)?.[0];
//   if (!currentResource) {
//     return <Loading />;
//   }

//   const {
//     options: { reportInterface },
//   } = currentResource;

//   return (
//     <ReportLayout
//       totalTables={LIST_REPORTS.length}
//       filterChildren={<GamePerformanceReportFilters reportServiceVersion={reportInterface?.serviceVersion} />}
//       resultChildren={<GamePerformanceReportResults />}
//     />
//   );
// }

// export default ReportGamePerformanceList;

// TODO: Remove the below code when apply 3-table result report
import React from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { REPORT_TYPES } from '../../constant/resource-slug';

export const LIST_REPORTS = [REPORT_TYPES.OVERALL, REPORT_TYPES.PREPAID, REPORT_TYPES.STANDARD];

function ReportGamePerformanceList(props) {

  return (
    <WealthListGuesser
      {...props}
      excludeFields={['betAmount']}
    />
  );
}

export default ReportGamePerformanceList;
