import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import { ReferenceInput } from '../../../../../base/components/ra/inputs';

const GroupInput = ({
  source, ...otherProps
}) => {
  const translate = useTranslate();

  return (
    <ReferenceInput
      variant="outlined"
      label={translate('resources.group.name')}
      source={source}
      reference="group"
      filterToQuery={value => ({
        'name||$cont': value,
      })}
      filter={{
        enabled: true,
      }}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      perPage={100}
      {...otherProps}
    >
      <AutocompleteInput
        optionText="name"
        resettable
      />
    </ReferenceInput>
  );
};

GroupInput.propTypes = {};

GroupInput.defaultProps = {};

export default GroupInput;
