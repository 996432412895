import { withStyles } from '@material-ui/styles';
import { BooleanInput } from 'react-admin';
import React from 'react';
import PropTypes from 'prop-types';

const Switcher = withStyles(theme => ({
  root: {
    width: 82,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '& + $track': {
      '&:after': {
        opacity: 0,
      },
      '&:before': {
        opacity: 1,
      },
    },
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
        '&:before': {
          opacity: 0,
        },
        '&:after': {
          opacity: 1,
        },
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
    margin: 2,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    '&:after, &:before': {
      color: 'white',
      fontSize: '11px',
      position: 'absolute',
      top: '5px',
    },
    '&:after': {
      content: props => `'${props.checkedLabel}'`,
      left: '16px',
    },
    '&:before': {
      content: props => `'${props.uncheckedLabel}'`,
      right: '13px',
    },
  },
  checked: {
    transform: 'translateX(56px) !important',
  },
  focusVisible: {},
}))(({
  // eslint-disable-next-line no-unused-vars
  classes, uncheckedLabel, checkedLabel, ...otherProps
}) => (
  <BooleanInput
    options={{
      classes: {
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      },
      focusVisibleClassName: classes.focusVisible,
      disableRipple: true,
    }}
    label={false}
    {...otherProps}
  />
));

Switcher.propTypes = {
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
};

Switcher.defaultProps = {
  checkedLabel: undefined,
  uncheckedLabel: undefined,
};

export default Switcher;
