import { Edit, RecordContext, useEditController, useLocale, useTranslate } from 'react-admin';
import React, { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import CampaignCreateUpdateForm from './components/CampaignCreateUpdateForm';
import resourceSlug from '../../constant/resource-slug';
import { useUpdateCustom } from '../../base/hooks';
import { formatCampaignData, parseCampaignData } from './components/utils';
import Actions from '../../base/components/ra/views/action-toolbar';
import { NavigationContext } from '../../App';
import { UUID_REGEX } from '../../constant';

const CampaignUpdate = props => {
  const t = useTranslate();
  const locale = useLocale();
  const { record } = useEditController(props);

  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.campaign.details-title'),
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.campaign.name'),
          link: `/${resourceSlug.CAMPAIGN}`,
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);

  const {
    update, updateLoading: isUpdating,
  } = useUpdateCustom({
    resource: resourceSlug.CAMPAIGN,
    successMessage: 'resources.campaign.updated-successfully',
    resourceTranslated: t(`resources.${resourceSlug.CAMPAIGN}.name`),
  });

  const handleSave = values => {
    const data = formatCampaignData(values);
    delete data.campaign.campaignCode;
    delete data.campaign.campaignType;
    delete data.campaign.issuer;
    delete data.campaign.brandId;

    update(resourceSlug.CAMPAIGN, record.id, data);
  };

  return (
    <Box
      component={Edit}
      {...props}
      width={{
        md: 960,
      }}
      mx="auto"
      actions={(
        <Actions
          data={record}
          resource={resourceSlug.CAMPAIGN}
          hasList
          hasShow
        />
      )}
    >
      <RecordContext.Consumer>
        {value => (
          <CampaignCreateUpdateForm
            save={handleSave}
            saving={isUpdating}
            record={parseCampaignData(value)}
          />
        )}
      </RecordContext.Consumer>
    </Box>
  );
};

export default CampaignUpdate;
