import { CACHE_CAMPAIGNS_BY_PAGE,
  CACHE_CAMPAIGN_ACTION_LOG_BY_PAGE,
  CACHE_CAMPAIGN_PLAYERS_BY_PAGE,
  UPDATE_CURRENT_CAMPAIGN_ACTION_LOG_PAGE,
  UPDATE_CURRENT_CAMPAIGN_PAGE,
  UPDATE_CURRENT_CAMPAIGN_PLAYER_PAGE } from '../root.actions';

export const cacheCampaignsByPage = ({
  data, page,
}) => ({
  type: CACHE_CAMPAIGNS_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentCampaignPage = data => ({
  type: UPDATE_CURRENT_CAMPAIGN_PAGE,
  payload: data,
});

export const cacheCampaignPlayersByPage = ({
  data, page,
}) => ({
  type: CACHE_CAMPAIGN_PLAYERS_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentCampaignPlayerPage = data => ({
  type: UPDATE_CURRENT_CAMPAIGN_PLAYER_PAGE,
  payload: data,
});

export const cacheCampaignActionLogByPage = ({
  data, page,
}) => ({
  type: CACHE_CAMPAIGN_ACTION_LOG_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentCampaignActionLogPage = data => ({
  type: UPDATE_CURRENT_CAMPAIGN_ACTION_LOG_PAGE,
  payload: data,
});
