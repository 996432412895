/* eslint-disable no-case-declarations */
import { CACHE_PLAYER_CAMPAIGN_USAGE_BY_PAGE, UPDATE_CURRENT_PLAYER_CAMPAIGN_USAGE_PAGE } from '../root.actions';

const initialPlayerCampaignUsageStates = {
  playerCampaignUsageList: {},
  currentPlayerCampaignUsagePage: 1,
};

export const playerCampaignUsageReducer = (state = initialPlayerCampaignUsageStates, action) => {
  switch (action.type) {
    case CACHE_PLAYER_CAMPAIGN_USAGE_BY_PAGE:
      const newPage = action.payload?.page || 1;
      return {
        ...state,
        currentPlayerCampaignUsagePage: newPage,
        playerCampaignUsageList:
          newPage === 1
            ? {
              1: action.payload?.data,
            }
            : {
              ...state.playerCampaignUsageList,
              [newPage]: action.payload?.data,
            },
      };

    case UPDATE_CURRENT_PLAYER_CAMPAIGN_USAGE_PAGE:
      return {
        ...state,
        currentPlayerCampaignUsagePage: action.payload,
      };
    default:
      return state;
  }
};
