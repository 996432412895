/* eslint-disable import/no-dynamic-require, global-require, import/no-unresolved */
import axios from 'axios';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { merge } from 'lodash';

const waLocale = localStorage?.getItem('waLocale')?.replace(/["]+/g, '');
let init = 1;

export const i18nProvider = polyglotI18nProvider(
  locale => {
    const localMessages = require(`./${locale}.messages.json`);

    if (init) {
      init = false;
      return localMessages;
    }

    const languages = axios.get(`api/i18n/backoffice/${locale}`).then(({ data }) => merge({}, data, localMessages));

    // load language from api
    return languages;
  },
  waLocale || 'en',
  {
    allowMissing: true,
  },
);
