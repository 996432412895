import { merge } from 'lodash';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@material-ui/core/styles';

import { AppThemeEnum } from '../constant';
import { theme as greenPacificQueenTheme } from './@GreenPacificQueenTheme';

export const HEADER_HEIGHT = {
  lg: 114,
  md: 72,
};

export const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const defaultTheme = {
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  direction: 'ltr',
  palette: {
    type: 'light',
    common: {
      black: '#000',
      white: '#fff',
      dark: '#020202',
    },
    primary: {
      main: '#0F417B',
      light: '#005EB8',
      contrastText: '#FFFFFF',
      subText: '#757575',
    },
    secondary: {
      main: '#F2F2F2',
      dark: '#C1C3CB',
      contrastText: '#2C324F',
    },
    success: {
      light: '#D7F5B1',
      main: '#188754',
      dark: '#5D9405',
    },
    info: {
      light: '#9BE7FD',
      main: '#0795F4',
      dark: '#0356AF',
    },
    warning: {
      light: '#ffdb6e',
      main: '#FF8C00',
      dark: '#D36F1A',
    },
    error: {
      light: '#ea868f',
      main: '#BC204B',
      dark: '#87061E',
    },
    sidebar: {
      bgColor: '#fff',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#0F417B',
      navHoverBgColor: 'rgb(229, 229, 229)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#0F417B',
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f4f4f7',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    btn: {
      hover: 'rgba(0, 0, 0, 0.08)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    popupColor: {
      main: '#fff',
    },
    mixedColors: [
      '#0873b7',
      '#f59c11',
      '#01c0ef',
      '#605ca8',
      '#06a558',
      '#de4c38',
      '#3d9970',
      '#0873b7',
      '#ff841a',
      '#d81c60',
    ],
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
  },
  overrides: {
    MuiMenuItem: {
      root: {
        '& *': {
          fontWeight: '500 !important',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        ':root': {
          '--headerHeight': `${HEADER_HEIGHT}px`,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: 20,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: 22,
        },
      },
      h2: {
        fontSize: 18,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: 20,
        },
      },
      h3: {
        fontSize: 16,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: 18,
        },
      },
      h4: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      h5: {
        fontSize: 14,
        fontWeight: 500,
      },
      h6: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0.5,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0.15,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0.1,
      },
      body1: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0.5,
      },
      body2: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.25,
      },
    },
    MuiTablePagination: {
      root: {
        overflow: 'auto',
      },
      spacer: {
        display: 'none',
      },
      toolbar: {
        width: 'fit-content',
        marginLeft: 'auto',

        [breakpoints.down(480)]: {
          width: 'auto',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: '16px 0px',
        },
      },
      actions: {
        '& button': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: '#fff',
      },
      input: {
        minWidth: '187px',
      },
    },
    MuiOutlinedInput: {
      root: {
        justifyContent: 'space-between',
      },
      input: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    MuiPickersDateRangePickerInput: {
      root: {
        width: '100%',
      },
    },
    MuiButton: {
      root: {
        margin: '4px',
        letterSpacing: 1.25,
        fontSize: 13,
        '&.Mui-disabled': {
          background: '#D1D1D1',
          color: '#00000042',
        },
        [breakpoints.down('md')]: {
          '&:hover.Mui-disabled': {
            backgroundColor: '#D1D1D1 !important',
            color: '#00000042 !important',
          },
        },
      },
      textSizeSmall: {
        background: '#FFF',
        padding: '8px 16px',
      },
      text: {
        boxShadow: 'none',
      },
      containedSizeSmall: {
        padding: '8px 16px',
        fontSize: '0.8125rem',
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: 4,
      },
    },
    MuiCardLg: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4,
        // boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiTableCell: {
      root: {
        textAlign: 'center',
        position: 'relative',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        '&:last-child': {
          borderRight: 'none',
        },
        '& [data-field="action"]': {
          minWidth: '60px',
        },
        '& > div': {
          margin: 'auto',
        },
      },
      head: {
        [breakpoints.down('md')]: {
          textAlign: 'center',
        },
      },
      sizeSmall: {
        padding: '6px',
      },
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    RaImageField: {
      image: {
        margin: 0,
        boxShadow: '2px 0px 11px -7px rgb(0 0 0 / 60%)',
        verticalAlign: 'middle',
      },
    },
    RaSaveButton: {
      button: {
        marginRight: '0px',
      },
    },
    RaSingleFieldList: {
      root: {
        '&.align-center': {
          justifyContent: 'center',
        },
      },
    },
    MuiChip: {
      root: {
        whiteSpace: 'none',
        height: 'auto',
      },
      label: {
        padding: '5px 8px !important',
        whiteSpace: 'none',

        // Make text ellipsis 3 line
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
      },
    },
    RaChipField: {
      chip: {
        width: '100%',
        maxWidth: 'fit-content',
      },
    },
    RaDatagrid: {
      headerCell: {
        fontWeight: 700,
        fontSize: 'small',
        overflow: 'hidden',
        textAlign: 'center',
        color: '#404040',
      },
      row: {
        height: 42,
      },
      rowCell: {
        overflow: 'hidden',
        textAlign: 'center',
        maxWidth: 100,
        wordWrap: 'break-word',
        [breakpoints.down('md')]: {
          maxWidth: 'unset',
          minWidth: '100px',
        },
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        height: '64px',
      },
    },
    RaTopToolbar: {
      root: {
        padding: '0px !important',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent !important',
        alignSelf: 'flex-end',
      },
    },
    RaList: {
      main: {},
      content: {
        [breakpoints.down('md')]: {
          overflow: 'scroll',
          width: '100%',
        },
      },
      bulkActionsDisplayed: {
        marginTop: '0',
      },
    },
    RaListToolbar: {
      toolbar: {
        alignItems: 'flex-start',
        backgroundColor: 'transparent !important',
      },
      actions: {
        alignSelf: 'flex-end !important',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'transparent !important',
      },
    },
    RaSelectInput: {
      input: {
        minWidth: '215px',
      },
    },
    RaNullableBooleanInput: {
      input: {
        minWidth: '120px',
      },
    },
    RaFilterForm: {
      form: {
        marginTop: 0,
      },
    },
    RaFilterFormInput: {
      body: {
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: '300px',
        marginRight: '12px',
        [breakpoints.down('md')]: {
          width: '280px',
        },
      },
    },
    RaTabbedShowLayout: {
      content: {
        width: '100%',
        padding: '16px !important',
        '& > span > div > div': {
          width: '100%',
        },
      },
    },
    RaTabbedForm: {
      content: {
        padding: '16px !important',
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsContainer: {
        zIndex: 999999,
      },
    },
    RaBooleanField: {
      root: {
        display: 'inline-block',
      },
    },
    RaSimpleFormIterator: {
      action: {
        paddingTop: '4px',
      },
    },
  },
};

const siteThemeMapping = {
  [AppThemeEnum.GREEN_PACIFIC_QUEEN]: greenPacificQueenTheme,
};

export const getRawTheme = APP_THEME => {
  const customTheme = siteThemeMapping[APP_THEME] || {};
  return createTheme(merge(defaultTheme, customTheme));
};
