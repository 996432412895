/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, useTheme, Box, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Toolbar, useTranslate, useRefresh, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useFormState, useForm } from 'react-final-form';
import keys from 'lodash/keys';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useLoading, useEditButton, useCreateButton } from '../../hooks';
import DeleteButton from '../ra/buttons/DeleteButton';
import { SaveButton as SaveButtonRA, Button } from '../ra/buttons';
import useViewType, { EnumViewType } from '../../hooks/useViewType';
import { showConfirmDialog } from '../../../services/redux/app/app.actions';

const useStyles = makeStyles(
  theme => ({
    defaultToolbar: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(1),
    },
    btnDelete: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  }),
  {
    name: 'RaToolbar',
  },
);

const valueOrDefault = (value, defaultValue) => (typeof value === 'undefined' ? defaultValue : value);

const SaveButton = props => {
  const {
    alwaysEnableSaveButton, pristine, disabled,
  } = props;
  const disabledBtn = disabled || !valueOrDefault(alwaysEnableSaveButton, !pristine);

  return (
    <SaveButtonRA
      {...props}
      invalid={false}
      disabled={disabledBtn}
    />
  );
};

SaveButton.propTypes = {
  alwaysEnableSaveButton: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export const WealthToolbar = props => {
  const {
    disabledBtnSave = false,
    hasDelete,
    alwaysEnableSaveButton,
    forceCustomSave,
    onCustomSave,
    onAfterSave,
    isAcl,
    confirmDialogInfo,
    translatedResourceName: defaultTranslatedResourceName,
    customChildren,
    ...rest
  } = props;

  const {
    record, resource,
  } = rest;

  const theme = useTheme();
  const classes = useStyles();
  const { toggleLoading } = useLoading();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitHelpText, setSubmitHelpText] = useState('');

  const refModified = useRef({});

  const { summitOnEdit } = useEditButton({
    ...props,
  });

  const { summitOnCreate } = useCreateButton({
    ...props,
  });

  const {
    values: formValues, modified,
  } = useFormState();

  const form = useForm();

  useEffect(() => {
    if (isEmpty(refModified.current)) {
      refModified.current = modified;
    }
  }, [modified]);

  const isModified = keys(modified).some(key => modified[key] === true) || keys(refModified.current).length !== keys(modified).length;
  const isDisabled = disabledBtnSave === false && !isModified && !alwaysEnableSaveButton;

  const { currentView } = useViewType();

  useEffect(() => {
    toggleLoading(rest.saving);
    return () => toggleLoading(false);
  }, [rest.saving]);

  const onDeleteSuccess = () => {
    const translatedResourceName = defaultTranslatedResourceName || translate(`resources.${resource}.name`);
    const onDeleteSuccessMessage = translate('ra.notification.deleted', {
      smart_name: translatedResourceName,
    });

    notify(onDeleteSuccessMessage, 'success');

    history.replace(`/${resource}`);
    refresh();
  };

  // Handle SAVE with dialog confirm
  const handleSaveWithConfirmDialog = values => {
    const hasCustomSave = typeof onCustomSave === 'function';
    const resourceTranslated = defaultTranslatedResourceName || translate(`resources.${resource}.name`);

    if (currentView === EnumViewType.CREATE) {
      const confirmCreateContent = translate('ra.message.confirmSubmitCreate', {
        smart_name: resourceTranslated.toLowerCase(),
      });

      dispatch(
        showConfirmDialog({
          isOpen: true,
          title: 'ra.action.create',
          content: confirmCreateContent,
          onConfirm: hasCustomSave ? onCustomSave.bind(this, values) : summitOnCreate,
          ...confirmDialogInfo,
        }),
      );
    } else if (currentView === EnumViewType.EDIT) {
      const confirmEditContent = translate('ra.message.confirmSubmitEdit', {
        smart_name: resourceTranslated.toLowerCase(),
      });

      dispatch(
        showConfirmDialog({
          isOpen: true,
          title: 'ra.action.edit',
          content: confirmEditContent,
          onConfirm: hasCustomSave ? onCustomSave.bind(this, values) : summitOnEdit,
          ...confirmDialogInfo,
        }),
      );
    }
  };

  const handleSave = values => {
    if (onCustomSave) {
      onCustomSave(values);

      if (typeof onAfterSave === 'function') {
        onAfterSave();
      }

      return;
    }

    if (currentView === EnumViewType.CREATE) {
      summitOnCreate();
    } else if (currentView === EnumViewType.EDIT) {
      summitOnEdit();
    }
  };

  const onSaveClick = () => {
    const fieldsInForm = [...keys(modified)];

    // Edit form need ID field
    if (currentView === EnumViewType.EDIT) {
      fieldsInForm.push('id');
    }

    // Format keys of data, only get key's level 1 (avoid `key.id`)
    const data = fieldsInForm.reduce((acc, curKey) => {
      const fieldName = curKey.split('.')[0];
      if ([undefined, null].includes(formValues[fieldName])) {
        acc[fieldName] = null;
      } else {
        acc[fieldName] = formValues[fieldName];
      }
      return acc;
    }, {});

    // Check validation form
    const someFieldInValid = keys(modified).some(d => !form?.getFieldState(d)?.valid);
    if (someFieldInValid) {
      form.submit(); // To trigger validation
      setSubmitHelpText(translate('ra.message.someFieldInValid'));
      return;
    }

    // Need to show confirm dialog when this resource is ACL
    if (isAcl) {
      if (forceCustomSave && typeof onCustomSave === 'function') {
        onCustomSave(data);
      } else {
        handleSaveWithConfirmDialog(data);
      }
    } else {
      handleSave(data);
    }
  };

  useEffect(() => {
    if (!submitHelpText) {
      return;
    }

    // Hide submit help text
    const allFieldsIsValid = keys(modified).every(d => form?.getFieldState(d)?.valid);
    if (allFieldsIsValid) {
      setSubmitHelpText('');
    }
  }, [modified, formValues]);

  return (
    <>
      <Toolbar {...rest}>
        <div className={classes.defaultToolbar}>
          {record && typeof record.id !== 'undefined' && hasDelete ? (
            <DeleteButton
              {...rest}
              className={classes.btnDelete}
              redirect="list"
              onSuccess={onDeleteSuccess}
            />
          ) : (
            <div />
          )}
          <Box
            display="flex"
            alignItems="center"
          >
            <Fade in={!!submitHelpText}>
              <Box
                color={theme.palette.error.main}
                marginRight="16px"
              >
                {submitHelpText}
              </Box>
            </Fade>
            {customChildren}
            <Button
              {...rest}
              label={translate('ra.action.save')}
              disabled={isDisabled}
              alwaysEnableSaveButton={alwaysEnableSaveButton}
              onClick={onSaveClick}
            />
          </Box>
        </div>
      </Toolbar>
    </>
  );
};

WealthToolbar.propTypes = {
  record: PropTypes.object,
  hasDelete: PropTypes.bool,
  isAcl: PropTypes.bool,
  transform: PropTypes.func,
  confirmDialogInfo: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  translatedResourceName: PropTypes.string,
  redirectUrl: PropTypes.string,
};

WealthToolbar.defaultProps = {
  record: {},
  hasDelete: null,
  isAcl: false,
  transform: f => f,
  confirmDialogInfo: null,
  translatedResourceName: '',
  redirectUrl: null,
};
