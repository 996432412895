import React from 'react';
import { useFormState } from 'react-final-form';
import { IntegerInput } from '../../../../../base/components/ra/inputs';

const StockInput = ({
  disabled, isEditForm, source, ...otherProps
}) => {
  const { initialValues = {} } = useFormState();
  return (
    <IntegerInput
      source={source}
      disabled={disabled || (isEditForm && initialValues?.[source] == null)}
      {...otherProps}
    />
  );
};

export default StockInput;
