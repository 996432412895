import { Box } from '@material-ui/core';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import React from 'react';
import { useField } from 'react-final-form';
import { IntegerInput } from '../../../../../base/components/ra/inputs';

const RewardRulesInput = ({
  source, disabled,
}) => {
  const totalRules = useField(source)?.input?.value?.length;

  return (
    <ArrayInput
      label={false}
      source={source}
      disabled={disabled}
    >
      <Box
        component={SimpleFormIterator}
        inline
        disableReordering
        disableAdd={totalRules === 5}
        disabled={disabled}
        sx={{
          '& section': {
            display: 'grid',
            gridTemplateColumns: 'repeat(3,1fr)',
            gap: 10,
          },
          "& [class*='button-remove'] [class*='RaButton-label']": {
            display: 'none',
          },
          "& [class*='error']": {
            display: totalRules >= 1 ? 'inline-block' : 'none', // To remove duplicated required error message
          },
        }}
      >
        <IntegerInput
          source="consecutiveLoginDays"
          label="resources.campaign.fields.consecutive-login-days"
          required
          fullWidth
          classes
        />
        <IntegerInput
          source="minimumSpins"
          label="resources.campaign.fields.min-spins"
          required
          fullWidth
        />
        <IntegerInput
          source="freeSpinReward"
          label="resources.campaign.fields.free-spins"
          required
          fullWidth
        />
      </Box>
    </ArrayInput>
  );
};

export default RewardRulesInput;
