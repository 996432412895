/* eslint-disable import/no-dynamic-require, global-require, import/no-cycle */
import * as React from 'react';
import { Route, useParams } from 'react-router-dom';
import { Resource } from 'react-admin';
import { CUSTOM_ROUTES } from '../../constant/customRoutes';
import { RoleBuiltIn } from '../../services/util';
import ResetPassword from '../../theme/@jumbo/components/Common/authComponents/ResetPassword';
import Profile from './profile';
import { QuickSetupNewGroupRoute, QuickSetupNewBrandRoute } from './quick-setup-components';
import { CUSTOM_RESOURCES } from '../../constant/customResources';
import PlayerFreeSpinList from '../campaign/components/PlayerFreeSpinList';
import resourceSlug from '../../constant/resource-slug';
import ChangeLogList from '../campaign/components/ChangeLogList';
import ChangeLogDetailDrawer from '../campaign/components/ChangeLogList/ChangeLogDetailDrawer';

export const customRoutes = [
  <Route
    exact
    path={CUSTOM_ROUTES.PROFILE}
    component={Profile}
  />,
  <Route
    exact
    path={CUSTOM_ROUTES.QUICK_SETUP_NEW_GROUP}
    component={QuickSetupNewGroupRoute}
    resource={CUSTOM_RESOURCES.QUICK_SETUP.name}
    accessibleRoleIds={[RoleBuiltIn.SYSTEM_ADMIN]}
  />,
  <Route
    exact
    path={CUSTOM_ROUTES.QUICK_SETUP_NEW_BRAND}
    component={QuickSetupNewBrandRoute}
    resource={CUSTOM_RESOURCES.QUICK_SETUP.name}
    accessibleRoleIds={[RoleBuiltIn.SYSTEM_ADMIN]}
  />,
  <Route
    exact
    path={CUSTOM_ROUTES.CAMPAIGN_PLAYER_FREE_SPINS}
    component={props => {
      const { id } = useParams();
      const resource = `${resourceSlug.CAMPAIGN}/${id}/players`;
      return (
        <>
          <Resource
            intent="registration"
            list={PlayerFreeSpinList}
            name={resource}
          />
          <PlayerFreeSpinList
            resource={resource}
            basePath={`/${resource}`}
            {...props}
          />
        </>
      );
    }}
  />,
  <Route
    path={CUSTOM_ROUTES.CAMPAIGN_CHANGES_LOG}
    component={props => {
      const { id } = useParams();
      const resource = `${resourceSlug.ACTION_LOG}/${id}/${resourceSlug.CAMPAIGN}`;

      return (
        <>
          <Resource
            intent="registration"
            list={ChangeLogList}
            show={ChangeLogDetailDrawer}
            name={resource}
          />
          <Resource
            intent="registration"
            show={ChangeLogDetailDrawer}
            name="action"
          />
          <ChangeLogList
            resource={resource}
            basePath={`/${resource}`}
            {...props}
          />
        </>
      );
    }}
  />,

];

export const publicCustomRoutes = [<Route
  exact
  path="/reset-password/:token"
  component={ResetPassword}
  noLayout
/>];
