/* eslint-disable import/no-cycle */
import PlayerFreeSpinList from '../routes/campaign/components/PlayerFreeSpinList';
import { RoleBuiltIn } from '../services/util';
import { REVERSE_INTEGRATION_OPERATORS } from './customRoutes';
import resourceSlug from './resource-slug';

export const CUSTOM_RESOURCES = {
  VERTBET_PARTNER: {
    name: `${REVERSE_INTEGRATION_OPERATORS.VERTBET.id}/partner`,
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
    options: {
      isAcl: true,
    },
  },
  VERTBET_PARTNER_INTEGRATION: {
    name: `${REVERSE_INTEGRATION_OPERATORS.VERTBET.id}/vertbet-wallet-integration`,
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
    options: {
      isAcl: true,
    },
  },
  QTECH_OPERATOR: {
    name: `${REVERSE_INTEGRATION_OPERATORS.QTECH.id}/operator`,
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
    options: {
      isAcl: true,
    },
  },
  QTECH_OPERATOR_INTEGRATION: {
    name: `${REVERSE_INTEGRATION_OPERATORS.QTECH.id}/wallet-integration`,
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
    options: {
      isAcl: true,
    },
  },
  QUICK_SETUP: {
    name: 'quick-setup',
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
  },
  REVERSE_INTEGRATION: {
    name: 'reverse-integration',
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
  },
  CAMPAIGN: {
    name: resourceSlug.CAMPAIGN,
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
  },
  CAMPAIGN_PLAYERS: {
    name: `${resourceSlug.CAMPAIGN}/:id/players`,
    list: PlayerFreeSpinList,
    intent: 'registration',
  },
};
